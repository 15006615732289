/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivitySessionCheckInDto,
    ActivitySessionCheckInDtoFromJSON,
    ActivitySessionCheckInDtoFromJSONTyped,
    ActivitySessionCheckInDtoToJSON,
    CustomError,
    CustomErrorFromJSON,
    CustomErrorFromJSONTyped,
    CustomErrorToJSON,
} from './';

/**
 * The response with a objects of type "T".
 * @export
 * @interface ActivitySessionCheckInDtoResponseItemViewModel
 */
export interface ActivitySessionCheckInDtoResponseItemViewModel {
    /**
     * Gets the list of errors.
     * @type {Array<CustomError>}
     * @memberof ActivitySessionCheckInDtoResponseItemViewModel
     */
    readonly errors?: Array<CustomError> | null;
    /**
     * 
     * @type {ActivitySessionCheckInDto}
     * @memberof ActivitySessionCheckInDtoResponseItemViewModel
     */
    item?: ActivitySessionCheckInDto;
}

export function ActivitySessionCheckInDtoResponseItemViewModelFromJSON(json: any): ActivitySessionCheckInDtoResponseItemViewModel {
    return ActivitySessionCheckInDtoResponseItemViewModelFromJSONTyped(json, false);
}

export function ActivitySessionCheckInDtoResponseItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionCheckInDtoResponseItemViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(CustomErrorFromJSON)),
        'item': !exists(json, 'item') ? undefined : ActivitySessionCheckInDtoFromJSON(json['item']),
    };
}

export function ActivitySessionCheckInDtoResponseItemViewModelToJSON(value?: ActivitySessionCheckInDtoResponseItemViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': ActivitySessionCheckInDtoToJSON(value.item),
    };
}


