import React from "react";
import VenueStatusItemComponent from "./OpenVenueItem";
import { VenueDto } from "../../api/models/VenueDto";

const VenueStatus: React.FC<any> = (props: any) => {
    return (
        <div>
        <main role="main" className="container pt-2 pt-lg-3">
            <div className="row justify-content-center p-3">
            <div className="col col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                <h5 className="medium-title">ACCÉS OBERT</h5>
                {props.openedVenues &&
                    props.openedVenues.map((venue: VenueDto) => {
                        return (
                            <div key={venue.oid}>
                                {" "}
                                <VenueStatusItemComponent 
                                    venue={venue} 
                                    {...props}
                                />{" "}
                            </div>
                        )
                    })
                }
            </div>
            </div>
        </main>
        </div>
    );
};

export default VenueStatus;