import * as React from "react";
import { useState } from "react";
import { Modal } from "reactstrap";

import TransactionModalComponent from "../TransactionModal";

const VenueDetailHistoryFreeAccess: React.FC<any> = (props: any) => {
  const [modal, setModal] = useState(false);

  const ticketCountOptions = (ticketCount: number) => {
    let count = new Array(ticketCount);
    let tickets: number[] = [];
    var i;
    for (i = 0; i < count.length; i++) {
      tickets.push(i + 1);
    }
    return tickets;
  };

  return (
    <div>
      <ul className="nav nav-tabs mt-1" id="pills-tab" role="tablist">
        {props.venue.currentActivitySession &&
          props.venue.currentActivitySession?.ticketsRequired && (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="pills-reserves-tab"
                data-toggle="pill"
                href="#pills-reserves"
                role="tab"
                aria-controls="pills-reserves"
                aria-selected="true"
              >
                Reserves
              </a>
            </li>
          )}
        <li className={"nav-item"}>
          <a
            className={
              props.venue.currentActivitySession &&
              props.venue.currentActivitySession?.ticketsRequired
                ? "nav-link"
                : "nav-link active"
            }
            id="pills-historial-tab"
            data-toggle="pill"
            href="#pills-historial"
            role="tab"
            aria-controls="pills-historial"
            aria-selected={
              props.venue.currentActivitySession &&
              props.venue.currentActivitySession?.ticketsRequired
                ? "false"
                : "true"
            }
          >
            Historial
          </a>
        </li>
      </ul>
      <div className="tab-content pt-2 pl-1" id="pills-tabContent">
        {props.venue.currentActivitySession &&
          props.venue.currentActivitySession?.ticketsRequired && (
            <div
              className="tab-pane fade show active p-2"
              id="pills-reserves"
              role="tabpanel"
              aria-labelledby="pills-reserves-tab"
            >
              <input
                className="form-control"
                type="text"
                placeholder="Cerca per nom i cognoms, correu-e o DNI"
                aria-label="Search"
                onChange={(e) => {
                  props.setSearchString(
                    (string: string) => (string = e.target.value)
                  );
                }}
              />

              <table className="table table-striped operations-table">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col-6">
                      Nom i cognoms
                    </th>
                    <th scope="col" className="col-3">
                      Reserves
                    </th>
                    <th scope="col" className="col-3">
                      Accés
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.loading &&
                  props.transactions &&
                  props.transactions.length === 0 ? (
                    <div className="text-center">
                      <div className="d-flex justify-content-center m-3">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                      <span className="text-primary">Carregant...</span>
                    </div>
                  ) : (
                    props.transactions &&
                    props.transactions.map((transaction: any, i: any) => {
                      let ticketCount: number = transaction.ticketCount;
                      return (
                        <tr className="d-flex" key={i}>
                          <td className="col-6">
                            {transaction.name + " "}
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#transactionModal"
                              onClick={(e: any) => {
                                e.preventDefault();
                                props.getSelectedTransaction(
                                  props.venue.currentActivitySession.oid,
                                  transaction.oid
                                );
                                setModal(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                <circle cx="8" cy="4.5" r="1" />
                              </svg>
                            </a>
                          </td>
                          <td className="col-3">
                            <select
                              className="custom-select"
                              id="inputGroupSelect01"
                              defaultValue={ticketCount}
                              onChange={(e) =>
                                (ticketCount = Number(e.target.value))
                              }
                            >
                              {ticketCountOptions(transaction.ticketCount).map(
                                (item: any, i: any) => {
                                  return (
                                    <option key={i} value={item}>
                                      {item.toString()}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </td>
                          <td className="col-3">
                            <button
                              className="btn btn-sm  btn-primary px-3 mb-2 rounded-button"
                              onClick={() => {
                                props.updateTransaction(
                                  props.venue.currentActivitySession.oid,
                                  transaction.oid,
                                  ticketCount
                                );
                              }}
                            >
                              Entrar
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}

        <div
          className={
            props.venue.currentActivitySession &&
            props.venue.currentActivitySession?.ticketsRequired
              ? "tab-pane fade p-2"
              : "tab-pane fade show active p-2"
          }
          id="pills-historial"
          role="tabpanel"
          aria-labelledby="pills-historial-tab"
        >
          <table className="table table-striped operations-table">
            <thead>
              <tr>
                <th style={{ width: "4em" }} scope="col">
                  Hora
                </th>
                {props.venue.currentActivitySession?.ticketsRequired ? (
                  <th scope="col">Reserva</th>
                ) : null}
                <th style={{ width: "6em" }} scope="col">
                  Moviment
                </th>
                <th style={{ width: "4em" }} scope="col">
                  Total
                </th>
                <th scope="col">Usuari</th>
              </tr>
            </thead>
            <tbody>
              {props.logs &&
                props.logs.map((log: any, i: any) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "4em" }}>
                        {new Date(log.date).toLocaleTimeString(
                          navigator.language,
                          {
                            hour: "2-digit",
                            hour12: false,
                            minute: "2-digit",
                          }
                        )}
                      </td>
                      {props.venue.currentActivitySession?.ticketsRequired ? (
                        <td>{log.transactionPersonName}</td>
                      ) : null}
                      <td style={{ width: "6em" }}>
                        {log.operation === 1 ? "Entrada" : "Sortida"}
                      </td>
                      <td style={{ width: "4em" }}>{log.currentCount}</td>
                      <td>{log.userFullName}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {props.selectedTransaction && props.selectedTransaction != null && (
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <TransactionModalComponent
            {...props}
            modal={modal}
            setModal={setModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default VenueDetailHistoryFreeAccess;
