import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";

import "./App.css";

import { ProtectedRoute } from "./views/ProtectedRoute";
import LoginLayout from "./layouts/Login/Login";
import * as Constants from "./utils/constants";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Constants.privateRoutes}>
          <ProtectedRoute />
        </Route>
        <Route>
          <LoginLayout />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
export default App;
