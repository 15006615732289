/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessLogListDto,
    AccessLogListDtoFromJSON,
    AccessLogListDtoFromJSONTyped,
    AccessLogListDtoToJSON,
} from './';

/**
 * ActivitySession state data transfer object.
 * @export
 * @interface ActivitySessionStateDto
 */
export interface ActivitySessionStateDto {
    /**
     * Gets or sets activitySession oid.
     * @type {string}
     * @memberof ActivitySessionStateDto
     */
    oid?: string;
    /**
     * Gets or sets a value indicating whether venue is currently open.
     * @type {boolean}
     * @memberof ActivitySessionStateDto
     */
    isOpen?: boolean;
    /**
     * Gets or sets venue capacity.
     * @type {number}
     * @memberof ActivitySessionStateDto
     */
    capacity?: number;
    /**
     * Gets or sets venue current occupancy.
     * @type {number}
     * @memberof ActivitySessionStateDto
     */
    occupancy?: number;
    /**
     * Gets or sets start on.
     * @type {Date}
     * @memberof ActivitySessionStateDto
     */
    startOn?: Date | null;
    /**
     * 
     * @type {AccessLogListDto}
     * @memberof ActivitySessionStateDto
     */
    accessLog?: AccessLogListDto;
}

export function ActivitySessionStateDtoFromJSON(json: any): ActivitySessionStateDto {
    return ActivitySessionStateDtoFromJSONTyped(json, false);
}

export function ActivitySessionStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionStateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'occupancy': !exists(json, 'occupancy') ? undefined : json['occupancy'],
        'startOn': !exists(json, 'startOn') ? undefined : (json['startOn'] === null ? null : new Date(json['startOn'])),
        'accessLog': !exists(json, 'accessLog') ? undefined : AccessLogListDtoFromJSON(json['accessLog']),
    };
}

export function ActivitySessionStateDtoToJSON(value?: ActivitySessionStateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'isOpen': value.isOpen,
        'capacity': value.capacity,
        'occupancy': value.occupancy,
        'startOn': value.startOn === undefined ? undefined : (value.startOn === null ? null : value.startOn.toISOString()),
        'accessLog': AccessLogListDtoToJSON(value.accessLog),
    };
}


