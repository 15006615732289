import * as React from "react";

const TransactionModal: React.FC<any> = (props: any) => {
    return (
        <div>
            <div className="modal-header">
                <h5>Reserva {props.selectedTransaction.name} - {props.selectedTransaction.activityName}</h5>
                <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                    props.setModal(false);
                }}
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div className="modal-body">
                <p>
                    <strong className="d-block small-title">
                        Informació de la reserva
                    </strong>
                    <small>
                        Codi de reserva: {props.selectedTransaction.transactionCode}<br/>
                        Data i hora reserva: {new Date(props.selectedTransaction.transactionDate).toLocaleDateString()} {new Date(props.selectedTransaction.transactionDate).toLocaleTimeString(navigator.language, {hour: "2-digit", minute: "2-digit"})}<br/>
                        Núm. de places reservades: {props.selectedTransaction.ticketsCount}<br/>
                        Nom de l'usuari: {props.selectedTransaction.name}<br/>
                        DNI de l'usuari: {props.selectedTransaction.id}<br/>
                        Correu de l'usuari: {props.selectedTransaction.email}<br/>
                        Telèfon de l'usuari: {props.selectedTransaction.phone}<br/>
                    </small>
                    {/* {props.selectedTransaction.customFields.length > 0 && (
                        props.selectedTransaction.customFields.map((customField: any) => {
                        return <small key={customField.customFieldIndex}>{customField.customFieldCaption + ": " + (customField.value == "0" ? "No" : customField.value == "1" ? "Sí" : customField.value)}<br/></small>;
                    }))} */}
                </p>
            </div>
        </div>
    )
};

export default TransactionModal;