import React from "react";
import { useState } from "react";

import FreeAccessVenueDetailComponent from "./FreeAccess/VenueDetail";
import FreeTicketsVenueDetailComponent from "./FreeTicket/VenueDetail";

const VenueDetail: React.FC<any> = (props: any) => {
  const [nightShift, setNightShift] = useState<boolean>(false);
  const nightShiftStyle: string = nightShift
    ? "col col-lg-6 col-md-12 col-sm-12 col-12 p-0 bg-black rounded shadow-sm"
    : "col col-lg-6 col-md-12 col-sm-12 col-12 p-0 bg-white rounded shadow-sm";

  const background: string =
    props.occupancy >= props.venue.currentActivitySession?.capacity
      ? "col col-lg-6 col-md-12 col-sm-12 col-12 p-0 bg-recinteple rounded shadow-sm"
      : nightShiftStyle;

  return (
    <div>
      <main role="main" className="container pt-3">
        <div className="row justify-content-md-center p-3">
          {props.venue.currentActivitySession?.ticketsRequired ? (
            <div className="col col-lg-6 col-md-12 col-sm-12 col-12 p-0 bg-white rounded shadow-sm">
              <FreeTicketsVenueDetailComponent {...props} />
            </div>
          ) : (
            <div className={background}>
              <FreeAccessVenueDetailComponent
                {...props}
                nightShift={nightShift}
                setNightShift={setNightShift}
              />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default VenueDetail;
