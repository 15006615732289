import React from "react";
import {useState} from "react";
import CloseVenueModal from "./CloseVenueModal";
import { Modal } from "reactstrap";

const VenueStatusItem: React.FC<any> = (props: any) => {
    const [modal, setModal] = useState(false);

  return (
      <div>

        <div className="list-group mt-3">
            <div
                className="list-group-item list-group-item-action mb-2"
            >
                <div className="row">
                    <div className="col-3 py-0 pl-2 pr-0">
                        <img
                        src={props.venue?.imageUrl}
                        alt={props.venue?.name}
                        className="mr-3 img-fluid rounded mx-auto d-block border"
                        />
                    </div>
                    <span className="col-9 mb-0 small lh-125  ">
                        <strong className="d-block small-title">{props.venue?.name}</strong>
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <React.Fragment>
                                    <a href="#" onClick={() => setModal(true)} className="badge p-2 closed-acces-badge mb-0 red bg-recintesred">
                                        <svg width="1em" height="1em" viewBox="0 1 16 16" className="bi bi-door-closed-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4 1a1 1 0 0 0-1 1v13H1.5a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2a1 1 0 0 0-1-1H4zm2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                        </svg> Tancar recinte
                                    </a>
                                </React.Fragment>
                            </div>
                            {props.venue.isPublished && (
                            <div className="mt-1 col-12 col-md-5">
                                <span className="badge bg-recintesblue pr-3 p-1 closed-acces-badge mb-2 text-white">
                                Reserva online
                                </span>
                            </div>
                            )}
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
            <CloseVenueModal 
                {...props}
                setModal={setModal} 
                modal={modal} 
            />
        </Modal>
      </div>
  );
};

export default VenueStatusItem;
