import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// API
import * as UserApi from "../../api/apis/UserApi";
import { UserLoginDto } from "../../api/models/UserLoginDto";

// Components
import LoginComponent from "../../components/Login/Login";

import * as Utils from "../../utils";
import * as Constants from "../../utils/constants";
import {Alert} from "../../models/alert";

const LoginLayout: React.FC<any> = (props: any) => {
  const [alert, setAlert] = useState<Alert>();

  let history = useHistory();

  document.title = Constants.appTitle + Constants.loginTitle;

  const loginPost = (values: UserLoginDto) => {
    new UserApi.UserApi()
      .loginPost({
        userLoginDto: {
          email: values.email,
          password: values.password,
        },
      })
      .then((data: any) => {
        Utils.setToken(data.item.token);
        history.push(Constants.listPath);
      })
      .catch((response) => {
        Utils.showError(setAlert, response);
      });
  };

  return (
    <div className="d-flex flex-column h-100 bg-white">
      <LoginComponent
        loginPost={loginPost}
        alert={alert}
        setAlert={setAlert}
      />
    </div>
  );
};

export default LoginLayout;
