/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
    TransactionDtoResponseItemViewModel,
    TransactionDtoResponseItemViewModelFromJSON,
    TransactionDtoResponseItemViewModelToJSON,
    TransactionListDtoResponseListViewModel,
    TransactionListDtoResponseListViewModelFromJSON,
    TransactionListDtoResponseListViewModelToJSON,
    TransactionPostDto,
    TransactionPostDtoFromJSON,
    TransactionPostDtoToJSON,
    TransactionPutDto,
    TransactionPutDtoFromJSON,
    TransactionPutDtoToJSON,
} from '../models';

export interface GetTransactionRequest {
    sessionId: string;
    transactionId: string;
}

export interface GetTransactionsRequest {
    sessionId: string;
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
    search?: string;
}

export interface PostTransactionRequest {
    sessionId: string;
    transactionPostDto?: TransactionPostDto;
}

export interface PostTransactionAndCheckinRequest {
    sessionId: string;
    transactionPostDto?: TransactionPostDto;
}

export interface PutTransactionRequest {
    sessionId: string;
    transactionId: string;
    transactionPutDto?: TransactionPutDto;
}

/**
 * 
 */
export class TransactionApi extends runtime.BaseAPI {

    /**
     * Gets a transaction of an ActivitySession.
     */
    async getTransactionRaw(requestParameters: GetTransactionRequest): Promise<runtime.ApiResponse<TransactionDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getTransaction.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/transactions/{transactionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Gets a transaction of an ActivitySession.
     */
    async getTransaction(requestParameters: GetTransactionRequest): Promise<TransactionDtoResponseItemViewModel> {
        const response = await this.getTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the transactions of an ActivitySession.
     */
    async getTransactionsRaw(requestParameters: GetTransactionsRequest): Promise<runtime.ApiResponse<TransactionListDtoResponseListViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getTransactions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/transactions`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionListDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Gets the transactions of an ActivitySession.
     */
    async getTransactions(requestParameters: GetTransactionsRequest): Promise<TransactionListDtoResponseListViewModel> {
        const response = await this.getTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new transaction.
     */
    async postTransactionRaw(requestParameters: PostTransactionRequest): Promise<runtime.ApiResponse<TransactionDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling postTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sessions/{sessionId}/transactions`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionPostDtoToJSON(requestParameters.transactionPostDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Creates a new transaction.
     */
    async postTransaction(requestParameters: PostTransactionRequest): Promise<TransactionDtoResponseItemViewModel> {
        const response = await this.postTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new transaction and checkin.
     */
    async postTransactionAndCheckinRaw(requestParameters: PostTransactionAndCheckinRequest): Promise<runtime.ApiResponse<TransactionDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling postTransactionAndCheckin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/transactions/checkin`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionPostDtoToJSON(requestParameters.transactionPostDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Creates a new transaction and checkin.
     */
    async postTransactionAndCheckin(requestParameters: PostTransactionAndCheckinRequest): Promise<TransactionDtoResponseItemViewModel> {
        const response = await this.postTransactionAndCheckinRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a transaction.
     */
    async putTransactionRaw(requestParameters: PutTransactionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling putTransaction.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling putTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/transactions/{transactionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionPutDtoToJSON(requestParameters.transactionPutDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a transaction.
     */
    async putTransaction(requestParameters: PutTransactionRequest): Promise<void> {
        await this.putTransactionRaw(requestParameters);
    }

}
