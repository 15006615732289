/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomFieldType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

export function CustomFieldTypeFromJSON(json: any): CustomFieldType {
    return CustomFieldTypeFromJSONTyped(json, false);
}

export function CustomFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldType {
    return json as CustomFieldType;
}

export function CustomFieldTypeToJSON(value?: CustomFieldType | null): any {
    return value as any;
}

