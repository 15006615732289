/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ActivitySession data transfer object for date look ups.
 * @export
 * @interface ActivitySessionDateLookupDto
 */
export interface ActivitySessionDateLookupDto {
    /**
     * Gets or sets the date.
     * @type {Date}
     * @memberof ActivitySessionDateLookupDto
     */
    date?: Date;
    /**
     * Gets or sets the number of tickets available for the date.
     * @type {number}
     * @memberof ActivitySessionDateLookupDto
     */
    availableTicketsCount?: number;
}

export function ActivitySessionDateLookupDtoFromJSON(json: any): ActivitySessionDateLookupDto {
    return ActivitySessionDateLookupDtoFromJSONTyped(json, false);
}

export function ActivitySessionDateLookupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionDateLookupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'availableTicketsCount': !exists(json, 'availableTicketsCount') ? undefined : json['availableTicketsCount'],
    };
}

export function ActivitySessionDateLookupDtoToJSON(value?: ActivitySessionDateLookupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'availableTicketsCount': value.availableTicketsCount,
    };
}


