/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Channel data transfer object.
 * @export
 * @interface TransactionPutDto
 */
export interface TransactionPutDto {
    /**
     * Gets or sets a value of the quantity of tickets requested.
     * @type {number}
     * @memberof TransactionPutDto
     */
    ticketCount: number;
}

export function TransactionPutDtoFromJSON(json: any): TransactionPutDto {
    return TransactionPutDtoFromJSONTyped(json, false);
}

export function TransactionPutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketCount': json['ticketCount'],
    };
}

export function TransactionPutDtoToJSON(value?: TransactionPutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticketCount': value.ticketCount,
    };
}


