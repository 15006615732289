import React from "react";
import { useHistory } from "react-router-dom";
import * as Constants from "../../utils/constants";

const NavBar: React.FC<any> = (props: any) => {
  let history = useHistory();

  return (
    <div>
      {history && history.location.pathname !== Constants.loginPath && (
        <div className="NavigationBar">
          <nav className="navbar  shadow-sm navbar-light bg-white">
            <div>
              <a className="navbar-brand" href={Constants.listPath}>
                <img
                  className="d-block mx-auto"
                  src={require("../../assets/img/Icona_Recintes_Dotze_de_juny.png")}
                  alt="Icona aforament recintes"
                  height="40"
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample01"
              aria-controls="navbarsExample01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarsExample01">
              <ul className="navbar-nav">
                {!history.location.pathname.includes(Constants.listPath) && (
                  <li className="nav-item active border-bottom">
                    <a
                      className="nav-link ml-auto text-right"
                      href={Constants.listPath}
                    >
                      Inici
                    </a>
                  </li>
                )}
                {!history.location.pathname.includes(Constants.venueStatusPath) && (    
                  <li className="nav-item active border-bottom">
                    <a
                      className="nav-link text-right"
                      href={Constants.venueStatusPath}
                    >
                      Tanca recintes
                    </a>
                  </li>
                )}
                <li className="nav-item">
                  <a
                    className="nav-link text-right"
                    href={Constants.loginPath}
                    onClick={() => sessionStorage.clear()}
                  >
                    Surt
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default NavBar;
