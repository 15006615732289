import * as React from "react";
import { useState, useEffect } from "react";
import * as Utils from "../../../../utils";
import { Modal } from "reactstrap";

import BookingModalComponent from "../BookingModalComponent";

import { VenueDto } from "../../../../api/models/VenueDto";

const VenueDetailCounterFreeAccess: React.FC<any> = (props: any) => {
  const [venue, setVenue] = useState(props.venue);
  const [modal, setModal] = useState(false);
  const [submittingForm, setSubmittingForm] = React.useState(false);

  useEffect(() => {
    let eventSource = Utils.getActivitySessionEventsourceConnection(
      props.currentSessionOid === ""
        ? venue.currentActivitySession?.oid
        : props.currentSessionOid
    );
    if (eventSource != undefined) {
      eventSource.onmessage = (e) => {
        venue.currentActivitySession === undefined &&
          props.setRefresh(new Date().getTime());
        updateVenue(JSON.parse(e.data));
      };
    }
  }, [props.currentSessionOid]);

  useEffect(() => {
    setVenue((venue: VenueDto) => props.venue);
  }, [props.venue]);

  const updateVenue = (data: any) => {
    data &&
      setVenue(
        (venue: VenueDto) =>
          (venue = {
            ...venue,
            currentActivitySession: {
              activityName: venue.currentActivitySession?.activityName,
              ticketsRequired: venue.currentActivitySession?.ticketsRequired,
              bookedTicketCount:
                venue.currentActivitySession?.bookedTicketCount,
              canBuyTicketsWithoutDiscount:
                venue.currentActivitySession?.canBuyTicketsWithoutDiscount,
              capacity: data.Capacity,
              highOccupancyLevel:
                venue.currentActivitySession?.highOccupancyLevel,
              isOpen: data.IsOpen,
              isPublished: venue.isPublished,
              mediumOccupancyLevel:
                venue.currentActivitySession?.mediumOccupancyLevel,
              occupancy: data.Occupancy,
              oid: data.Oid,
              startOn: data.StartOn,
              maxTicketsPerTransaction:
                venue.currentActivitySession?.maxTicketsPerTransaction,
              availableTicketCount:
                venue.currentActivitySession?.availableTicketCount,
            },
          })
      );
    props.setNewAccessLog(data);
  };

  return (
    <div>
      <div className="row justify-content-center text-center">
        <div className="col col-auto">
          <h1 className="big-title mt-4">{venue?.name}</h1>
          {venue.currentActivitySession?.activityName && (
            <span className="row justify-content-center blue2">
              {venue.currentActivitySession?.activityName}
            </span>
          )}
          <div>
            <label className="d-block">
              Accés{" "}
              {venue && venue.currentActivitySession?.isOpen
                ? "obert"
                : "tancat"}
              {venue &&
                venue.currentActivitySession?.startOn !== undefined &&
                venue.currentActivitySession?.isOpen && (
                  <span className="small gray">
                    {" des de les " +
                      new Date(
                        venue.currentActivitySession?.startOn
                      ).toLocaleTimeString(navigator.language, {
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                      })}
                  </span>
                )}
            </label>
          </div>
        </div>
      </div>

      {venue?.currentActivitySession?.isOpen && (
        <div className="text-center">
          <span className="medium-title mb-0">
            <span className="counter-reserves">
              {venue.currentActivitySession?.occupancy}
            </span>
            /{venue.currentActivitySession?.capacity} places (
            {Math.round(
              (venue.currentActivitySession?.occupancy * 100) /
                venue.currentActivitySession?.capacity
            )}
            %)
          </span>
          {venue.currentActivitySession &&
            venue.currentActivitySession.ticketsRequired && (
              <span className="d-block margin-counter ">
                {venue.currentActivitySession?.bookedTicketCount
                  ? venue.currentActivitySession.bookedTicketCount
                  : "0"}{" "}
                places reservades /{" "}
                <span className="text-success">
                  {venue.currentActivitySession?.capacity -
                    (venue.currentActivitySession?.bookedTicketCount !== 0
                      ? 0
                      : venue.currentActivitySession?.bookedTicketCount)}{" "}
                  disponibles
                </span>
              </span>
            )}
        </div>
      )}
      <div className="row justify-content-center my-4">
        <div className="col col-auto">
          <button
            type="button"
            className="btn btn-warning shadow btn-circle btn-xl"
            onClick={() =>
              venue.currentActivitySession?.occupancy > 0 &&
              props.updateOccupancy(venue.currentActivitySession.oid, 2)
            }
          >
            <svg
              className="bi bi-dash"
              width="3em"
              height="3em"
              viewBox="2.5 1 21 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
        </div>
        <div className="col col-auto">
          <button
            type="button"
            data-toggle="modal"
            data-target="#reservaModal"
            className="btn btn-danger shadow btn-circle btn-xl"
            onClick={() => {
              if (venue.currentActivitySession?.isOpen) {
                venue.currentActivitySession.ticketsRequired
                  ? setModal(true)
                  : props.updateOccupancy(venue.currentActivitySession.oid, 1);
              }
              setSubmittingForm(false);
            }}
          >
            <svg
              className="bi bi-plus"
              width="3em"
              height="3em"
              viewBox="2.5 1 21 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
              />
              <path
                fillRule="evenodd"
                d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
              />
            </svg>
          </button>
        </div>
      </div>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <BookingModalComponent
          {...props}
          modal={modal}
          setModal={setModal}
          session={venue.currentActivitySession}
          submittingForm={submittingForm}
          setSubmittingForm={setSubmittingForm}
        />
      </Modal>
    </div>
  );
};

export default VenueDetailCounterFreeAccess;
