/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * CustomFieldValue POST data transfer object.
 * @export
 * @interface CustomFieldValueDto
 */
export interface CustomFieldValueDto {
    /**
     * Gets or sets custom field value oid.
     * @type {string}
     * @memberof CustomFieldValueDto
     */
    oid?: string;
    /**
     * Gets or sets the custom field value.
     * @type {string}
     * @memberof CustomFieldValueDto
     */
    value?: string | null;
    /**
     * Gets or sets the custom field caption.
     * @type {string}
     * @memberof CustomFieldValueDto
     */
    customFieldCaption?: string | null;
    /**
     * Gets or sets the custom field index.
     * @type {number}
     * @memberof CustomFieldValueDto
     */
    customFieldIndex?: number;
}

export function CustomFieldValueDtoFromJSON(json: any): CustomFieldValueDto {
    return CustomFieldValueDtoFromJSONTyped(json, false);
}

export function CustomFieldValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'customFieldCaption': !exists(json, 'customFieldCaption') ? undefined : json['customFieldCaption'],
        'customFieldIndex': !exists(json, 'customFieldIndex') ? undefined : json['customFieldIndex'],
    };
}

export function CustomFieldValueDtoToJSON(value?: CustomFieldValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'value': value.value,
        'customFieldCaption': value.customFieldCaption,
        'customFieldIndex': value.customFieldIndex,
    };
}


