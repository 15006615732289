import { Alert } from "../models/alert";

declare interface Error {
  code: number;
  message: string;
}

export const errors: any[] = [
  {
    //Unknown
    message: "Hi ha hagut un error desconegut.",
    code: 1,
  },
  {
    //NotImplemented
    message: "Hi ha hagut un error desconegut.",
    code: 2,
  },
  {
    //NoJsonFoundInBody
    message: "No s'han trobat les dades.",
    code: 3,
  },
  {
    //EmailIsRequired
    message: "Introdueixi el correu electrònic per continuar.",
    code: 4,
  },
  {
    //PasswordIsRequired
    message: "Introdueixi la contrasenya per continuar.",
    code: 5,
  },
  {
    //InvalidUsernameOrPassword
    message: "El correu electrònic i/o la contrassenya són incorrectes.",
    code: 6,
  },
  {
    //UserNotFound
    message: "No s'ha trobat l'usuari introduit.",
    code: 7,
  },
  {
    //UserOidClaimNotPresent
    message: "No s'ha trobat l'usuari d'accés.",
    code: 8,
  },
  {
    //VenueNotFound
    message: "No s'ha trobat el recinte.",
    code: 9,
  },
  {
    //VenueIsClosed
    message: "El recinte està tancat.",
    code: 10,
  },
  {
    //VenueIsOpen
    message: "El recinte està obert.",
    code: 11,
  },
  {
    //VenueIsEmpty
    message: "El recinte està buit.",
    code: 12,
  },
  {
    //VenueNotAuthorized
    message: "No té permisos per a aquest recinte.",
    code: 13,
  },
  {
    //ActivityNotFound
    message: "No s'ha trobat l'activitat.",
    code: 14,
  },
  {
    //ActivityIsClosed
    message: "L'activitat està tancada.",
    code: 15,
  },
  {
    //ChannelNotFound
    message: "No s'ha trobat el canal.",
    code: 16,
  },
  {
    //AnyActivityFound
    message: "No s'ha trobat cap activitat.",
    code: 17,
  },
  {
    //ActivitySessionNotFound
    message: "No s'ha trobat la sessió.",
    code: 18,
  },
  {
    //NoneActivitySessionFound
    message: "No s'ha trobat cap sessió.",
    code: 19,
  },
  {
    //UserWithNoPermissionsOnThisActivity
    message: "L'usuari no té permisos per a l'activitat.",
    code: 20,
  },
  {
    //IdIsReuired
    message: "No s'ha pogut identificar l'element.",
    code: 21,
  },
  {
    //NameIsRequired
    message: "El nom és obligatori.",
    code: 22,
  },
  {
    //PhoneIsRequired
    message: "El telèfon és obligatori.",
    code: 23,
  },
  {
    //PostalCodeIsRequired
    message: "El codi postal és obligatori.",
    code: 24,
  },
  {
    //CanSendInfoIsRequired
    message: "Ha de marcar la casella de l'enviament d'informació.",
    code: 25,
  },
  {
    //TooManyTicketsRequestes
    message: "S'han seleccionat masses tiquets.",
    code: 26,
  },
  {
    //NoTicketsAvailable
    message: "No hi ha tiquets disponibles.",
    code: 27,
  },
  {
    //AccessOperationsIsRequired
    message: "No s'ha introduït cap valor.",
    code: 28,
  },
  {
    //TransactionNotFound
    message: "No s'ha trobat la reserva.",
    code: 29,
  },
  {
    //DiscountOidIsRequired
    message: "Ha de seleccionar un tipus de tiquet.",
    code: 30,
  },
  {
    //DiscountNotFound
    message: "No s'han trobat els tiquets seleccionades.",
    code: 31,
  },
  {
    //DiscountTotallySold
    message: "Ja s'han venut totes els tiquets d'aquest tipus.",
    code: 32,
  },
  {
    //DatabaseLockError
    message: "Hi ha hagut un error de connexió amb la base de dades.",
    code: 33,
  },
  {
    //TicketCountIsRequired
    message: "Ha de seleccionar la quantitat de tiquets.",
    code: 34,
  },
  {
    //DiscountNotAvailable
    message: "No hi ha tiquets disponibles.",
    code: 35,
  },
];

export function getError(codes?: any[]): string {
  let messages: string[] = [];

  if (codes === undefined || codes.length < 1) {
    codes = [999];
  }

  codes.forEach((apiError: any) => {
    let errorMessage = errors.some((error: Error) => {
      return error.code === apiError.code;
    })
      ? errors.find((listItem) => listItem.code === apiError.code).message
      : "UnregisteredError";

    messages.push(errorMessage);
  });
  return messages.join(" ");
}


export function showError(setAlert: any, response: any) {
  response
    .json()
    .then((data: any) => {
      setAlert((alert: Alert) => alert = {
        text: getError(data.errors),
        color: "danger",
        visible: true,
        loginlink: false,
      });
    })
    .catch(() => {
      if (response.status === 401) {
        setAlert((alert: Alert) => alert = {
          text: "La sessió ha expirat, cal iniciar-la de nou.",
          color: "danger",
          visible: true,
          loginlink: true,
        });
      } else {
        setAlert((alert: Alert) => alert = {
          text: "Hi ha hagut inesperat.",
          color: "danger",
          visible: true,
          loginlink: false,
        });
      }
    });
}
