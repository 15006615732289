/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOperation,
    AccessOperationFromJSON,
    AccessOperationFromJSONTyped,
    AccessOperationToJSON,
} from './';

/**
 * AccessOperationPost data transfer object.
 * @export
 * @interface AccessOperationPostDto
 */
export interface AccessOperationPostDto {
    /**
     * 
     * @type {AccessOperation}
     * @memberof AccessOperationPostDto
     */
    accessOperation: AccessOperation;
}

export function AccessOperationPostDtoFromJSON(json: any): AccessOperationPostDto {
    return AccessOperationPostDtoFromJSONTyped(json, false);
}

export function AccessOperationPostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessOperationPostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessOperation': AccessOperationFromJSON(json['accessOperation']),
    };
}

export function AccessOperationPostDtoToJSON(value?: AccessOperationPostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessOperation': AccessOperationToJSON(value.accessOperation),
    };
}


