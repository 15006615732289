/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ActivitySession data transfer object.
 * @export
 * @interface ActivitySessionDto
 */
export interface ActivitySessionDto {
    /**
     * Gets or sets activitySession oid.
     * @type {string}
     * @memberof ActivitySessionDto
     */
    oid?: string;
    /**
     * Gets or sets a value indicating whether venue is currently open.
     * @type {boolean}
     * @memberof ActivitySessionDto
     */
    isOpen?: boolean;
    /**
     * Gets or sets venue capacity.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    capacity?: number;
    /**
     * Gets or sets venue current occupancy.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    occupancy?: number;
    /**
     * Gets or sets start on.
     * @type {Date}
     * @memberof ActivitySessionDto
     */
    startOn?: Date | null;
    /**
     * Gets or sets a value indicating whether the session is published or not.
     * @type {boolean}
     * @memberof ActivitySessionDto
     */
    isPublished?: boolean;
    /**
     * Gets or sets medium occupancy level.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    mediumOccupancyLevel?: number;
    /**
     * Gets or sets high occupancy level.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    highOccupancyLevel?: number;
    /**
     * Gets or sets a value indicating whether tickets can be bought in any quantity.
     * @type {boolean}
     * @memberof ActivitySessionDto
     */
    canBuyTicketsWithoutDiscount?: boolean;
    /**
     * Gets or sets tickets already booked in the session.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    bookedTicketCount?: number;
    /**
     * Gets or sets a value indicating whether tickets are required or not.
     * @type {boolean}
     * @memberof ActivitySessionDto
     */
    ticketsRequired?: boolean;
    /**
     * Gets or sets the name of the activity.
     * @type {string}
     * @memberof ActivitySessionDto
     */
    activityName?: string | null;
    /**
     * Gets or sets the maximum number of tickets that can be bought per transaction.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    maxTicketsPerTransaction?: number;
    /**
     * Gets or sets the number of tickets available in the session.
     * @type {number}
     * @memberof ActivitySessionDto
     */
    availableTicketCount?: number;
}

export function ActivitySessionDtoFromJSON(json: any): ActivitySessionDto {
    return ActivitySessionDtoFromJSONTyped(json, false);
}

export function ActivitySessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'occupancy': !exists(json, 'occupancy') ? undefined : json['occupancy'],
        'startOn': !exists(json, 'startOn') ? undefined : (json['startOn'] === null ? null : new Date(json['startOn'])),
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'mediumOccupancyLevel': !exists(json, 'mediumOccupancyLevel') ? undefined : json['mediumOccupancyLevel'],
        'highOccupancyLevel': !exists(json, 'highOccupancyLevel') ? undefined : json['highOccupancyLevel'],
        'canBuyTicketsWithoutDiscount': !exists(json, 'canBuyTicketsWithoutDiscount') ? undefined : json['canBuyTicketsWithoutDiscount'],
        'bookedTicketCount': !exists(json, 'bookedTicketCount') ? undefined : json['bookedTicketCount'],
        'ticketsRequired': !exists(json, 'ticketsRequired') ? undefined : json['ticketsRequired'],
        'activityName': !exists(json, 'activityName') ? undefined : json['activityName'],
        'maxTicketsPerTransaction': !exists(json, 'maxTicketsPerTransaction') ? undefined : json['maxTicketsPerTransaction'],
        'availableTicketCount': !exists(json, 'availableTicketCount') ? undefined : json['availableTicketCount'],
    };
}

export function ActivitySessionDtoToJSON(value?: ActivitySessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'isOpen': value.isOpen,
        'capacity': value.capacity,
        'occupancy': value.occupancy,
        'startOn': value.startOn === undefined ? undefined : (value.startOn === null ? null : value.startOn.toISOString()),
        'isPublished': value.isPublished,
        'mediumOccupancyLevel': value.mediumOccupancyLevel,
        'highOccupancyLevel': value.highOccupancyLevel,
        'canBuyTicketsWithoutDiscount': value.canBuyTicketsWithoutDiscount,
        'bookedTicketCount': value.bookedTicketCount,
        'ticketsRequired': value.ticketsRequired,
        'activityName': value.activityName,
        'maxTicketsPerTransaction': value.maxTicketsPerTransaction,
        'availableTicketCount': value.availableTicketCount,
    };
}


