import { VenueDto } from "../api/models/VenueDto";

export const ApiBasePath: string = process.env.REACT_APP_API_URL as string;

export const appTitle: string = "Gestió de recintes - ";
export const loginTitle: string = "Inici sessió";
export const venueListTitle:string = "Canviar recinte";
export const venueDetailTitle:string = "Detall recinte";

export const refreshInterval:number = 30000;

export const tokenKey:string = "token";

export const loginPath:string = "/login";
export const venueStatusPath:string = "/venuestatus"
export const listPath:string = "/venuelist";
export const detailPath:string = "/venuedetail/:venueid";
export const privateRoutes:string[] = [listPath, venueStatusPath, detailPath];

export const venue: VenueDto = {
    oid: "",
    name: "",
    description: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    mapUrl: "",
    imageUrl: "",
    moreInfoUrl: "",
    currentActivitySession: {
      oid: "",
      isOpen: false,
      capacity: 0,
      occupancy: 0,
      startOn: null,
      isPublished: false,
      mediumOccupancyLevel: 0,
      highOccupancyLevel: 0,
      canBuyTicketsWithoutDiscount: false,
      maxTicketsPerTransaction: 0,
      availableTicketCount: 0,
    },
    isPublished: false,
}

//Max tickets
export const maxTickets: number = 10;

//Validation messages
export const emailFormat: string = "Comprobi que el correu electrònic és correcte.";