/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
    TokenDtoResponseItemViewModel,
    TokenDtoResponseItemViewModelFromJSON,
    TokenDtoResponseItemViewModelToJSON,
    UserDtoResponseItemViewModel,
    UserDtoResponseItemViewModelFromJSON,
    UserDtoResponseItemViewModelToJSON,
    UserLoginDto,
    UserLoginDtoFromJSON,
    UserLoginDtoToJSON,
} from '../models';

export interface LoginPostRequest {
    userLoginDto?: UserLoginDto;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * User login.
     */
    async loginPostRaw(requestParameters: LoginPostRequest): Promise<runtime.ApiResponse<TokenDtoResponseItemViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginDtoToJSON(requestParameters.userLoginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * User login.
     */
    async loginPost(requestParameters: LoginPostRequest): Promise<TokenDtoResponseItemViewModel> {
        const response = await this.loginPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * User profile.
     */
    async usersMeGetRaw(): Promise<runtime.ApiResponse<UserDtoResponseItemViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * User profile.
     */
    async usersMeGet(): Promise<UserDtoResponseItemViewModel> {
        const response = await this.usersMeGetRaw();
        return await response.value();
    }

}
