import React from "react";
import VenueOccupancyBar from "./VenueOccupancyBar";

const VenueList: React.FC<any> = (props: any) => {
  const occupancyPercent: number =
    props.venue.capacity <= 0
      ? 0
      : Math.trunc(
          (props.venue.currentActivitySession?.occupancy * 100) /
            props.venue.currentActivitySession?.capacity
        );

  return (
    <div className="list-group mt-3">
      <a
        href={"/venuedetail/" + props.venue.oid}
        className="list-group-item list-group-item-action mb-2"
      >
        <div className="row">
          <div className="col-3 py-0 pl-2 pr-0">
            <img
              src={props.venue?.imageUrl}
              alt={props.venue?.name}
              className="mr-3 img-fluid rounded mx-auto d-block border"
            />
          </div>

          <span className="col-9 mb-0 small lh-125  ">
            <strong className="d-block small-title">{props.venue?.name}</strong>

            {props.venue.currentActivitySession?.isOpen && (
              <React.Fragment>
                {props.venue?.currentActivitySession.activityName &&
                  (props.venue?.currentActivitySession.activityName != null ||
                    props.venue?.currentActivitySession.activityName !== "") && (
                    <h6 className="blue2 mb-0">
                      {props.venue.currentActivitySession.activityName}
                    </h6>
                  )}
                <div className="row">
                  <div
                    className={
                      props.venue.isPublished
                        ? "col-12 col-md-7 pb-2"
                        : "col-12 col-md-12 pb-2"
                    }
                  >
                    <span className="gray">
                      {" "}
                      <b className="black">
                        {props.venue && occupancyPercent}%
                      </b>{" "}
                      d'ocupació ({" "}
                      <b className="black">
                        {props.venue.currentActivitySession?.occupancy}
                      </b>{" "}
                      / {props.venue.currentActivitySession?.capacity} )
                    </span>
                    <div className="progress">
                      <VenueOccupancyBar venue={props.venue} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div className="row pt-1">
              {props.venue.currentActivitySession?.isOpen && (
                <React.Fragment>
                  <div className="col-6">
                    <span className="badge bg-recintesblue pr-3 p-2 closed-acces-badge mb-0 text-white">
                      <svg
                        className="bi bi-unlock-fill  mr-2"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M.5 9a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2V9z" />
                        <path
                          fill-rule="evenodd"
                          d="M8.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"
                        />
                      </svg>
                      Accés obert
                    </span>
                  </div>
                </React.Fragment>
              )}

              {!props.venue.currentActivitySession && (
                  <React.Fragment>
                    <div className="col-6">
                        <span className="badge border  p-2 closed-acces-badge mb-0 gray">
                          <svg
                            className="bi bi-lock-fill mr-2"
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="11" height="9" x="2.5" y="7" rx="2"></rect>
                            <path
                              fill-rule="evenodd"
                              d="M4.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"
                            ></path>
                          </svg>
                          Accés Tancat
                        </span>
                    </div>
                  </React.Fragment>
              )}
              
              {(props.venue.currentActivitySession?.isOpen || !props.venue.currentActivitySession) && 
                props.venue.isPublished && (
                  <React.Fragment>
                    <div className="col-6">
                      <span className="badge bg-recintesblue pr-3 p-2 closed-acces-badge mb-2 text-white">
                        Reserva online
                      </span>
                    </div>
                  </React.Fragment>
              )}

              {!props.venue.isVisible && (
                <React.Fragment>
                  <div className="col-6">
                    <span className="badge  bg-recintesgray p-2 closed-acces-badge mb-0 text-white">
                      <svg
                        className="bi bi-eye-slash mr-2"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                        <path
                          fill-rule="evenodd"
                          d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                        />
                      </svg>
                      No visible
                    </span>
                  </div>
                </React.Fragment>
              )}
            </div>
          </span>
        </div>
      </a>
    </div>
  );
};

export default VenueList;
