/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomError,
    CustomErrorFromJSON,
    CustomErrorFromJSONTyped,
    CustomErrorToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './';

/**
 * The response with a objects of type "T".
 * @export
 * @interface UserDtoResponseItemViewModel
 */
export interface UserDtoResponseItemViewModel {
    /**
     * Gets the list of errors.
     * @type {Array<CustomError>}
     * @memberof UserDtoResponseItemViewModel
     */
    readonly errors?: Array<CustomError> | null;
    /**
     * 
     * @type {UserDto}
     * @memberof UserDtoResponseItemViewModel
     */
    item?: UserDto;
}

export function UserDtoResponseItemViewModelFromJSON(json: any): UserDtoResponseItemViewModel {
    return UserDtoResponseItemViewModelFromJSONTyped(json, false);
}

export function UserDtoResponseItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDtoResponseItemViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(CustomErrorFromJSON)),
        'item': !exists(json, 'item') ? undefined : UserDtoFromJSON(json['item']),
    };
}

export function UserDtoResponseItemViewModelToJSON(value?: UserDtoResponseItemViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': UserDtoToJSON(value.item),
    };
}


