/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
    VenueDtoResponseItemViewModel,
    VenueDtoResponseItemViewModelFromJSON,
    VenueDtoResponseItemViewModelToJSON,
    VenueDtoResponseListViewModel,
    VenueDtoResponseListViewModelFromJSON,
    VenueDtoResponseListViewModelToJSON,
} from '../models';

export interface ChannelsChannelIdVenuesVenueIdGetRequest {
    channelId: string;
    venueId: string;
}

export interface GetVenuesRequest {
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
}

export interface VenuesVenueIdGetRequest {
    venueId: string;
}

/**
 * 
 */
export class VenueApi extends runtime.BaseAPI {

    /**
     * Get venue by its Oid.
     */
    async channelsChannelIdVenuesVenueIdGetRaw(requestParameters: ChannelsChannelIdVenuesVenueIdGetRequest): Promise<runtime.ApiResponse<VenueDtoResponseItemViewModel>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdVenuesVenueIdGet.');
        }

        if (requestParameters.venueId === null || requestParameters.venueId === undefined) {
            throw new runtime.RequiredError('venueId','Required parameter requestParameters.venueId was null or undefined when calling channelsChannelIdVenuesVenueIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/channels/{channelId}/venues/{venueId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))).replace(`{${"venueId"}}`, encodeURIComponent(String(requestParameters.venueId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VenueDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Get venue by its Oid.
     */
    async channelsChannelIdVenuesVenueIdGet(requestParameters: ChannelsChannelIdVenuesVenueIdGetRequest): Promise<VenueDtoResponseItemViewModel> {
        const response = await this.channelsChannelIdVenuesVenueIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the venues allowed to current user.
     */
    async getVenuesRaw(requestParameters: GetVenuesRequest): Promise<runtime.ApiResponse<VenueDtoResponseListViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/venues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VenueDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Get the venues allowed to current user.
     */
    async getVenues(requestParameters: GetVenuesRequest): Promise<VenueDtoResponseListViewModel> {
        const response = await this.getVenuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get venue by its Oid.
     */
    async venuesVenueIdGetRaw(requestParameters: VenuesVenueIdGetRequest): Promise<runtime.ApiResponse<VenueDtoResponseItemViewModel>> {
        if (requestParameters.venueId === null || requestParameters.venueId === undefined) {
            throw new runtime.RequiredError('venueId','Required parameter requestParameters.venueId was null or undefined when calling venuesVenueIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/venues/{venueId}`.replace(`{${"venueId"}}`, encodeURIComponent(String(requestParameters.venueId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VenueDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Get venue by its Oid.
     */
    async venuesVenueIdGet(requestParameters: VenuesVenueIdGetRequest): Promise<VenueDtoResponseItemViewModel> {
        const response = await this.venuesVenueIdGetRaw(requestParameters);
        return await response.value();
    }

}
