import React from "react";
import { Form, Field } from "react-final-form";
import { Tooltip } from "reactstrap";

import * as Utils from "../../../utils"

const VenueBookingModal: React.FC<any> = (props: any) => {
  const [ticketTooltipOpen, setTicketTooltipOpen] = React.useState<boolean>(false);
  const ticketToggle = () => setTicketTooltipOpen(!ticketTooltipOpen);
  const [idTooltipOpen, setIdTooltipOpen] = React.useState<boolean>(false);
  const idToggle = () => setIdTooltipOpen(!idTooltipOpen);
  const [nameTooltipOpen, setNameTooltipOpen] = React.useState<boolean>(false);
  const nameToggle = () => setNameTooltipOpen(!nameTooltipOpen);
  const [emailTooltipOpen, setEmailTooltipOpen] = React.useState<boolean>(false);
  const emailToggle = () => setEmailTooltipOpen(!emailTooltipOpen);
  const [phoneTooltipOpen, setPhoneTooltipOpen] = React.useState<boolean>(false);
  const phoneToggle = () => setPhoneTooltipOpen(!phoneTooltipOpen);

  var maxTickets = Utils.maxTickets;
  if (props.session) {
    maxTickets = props.session.maxTicketsPerTransaction;
    if (maxTickets > props.session.availableTicketCount)
      maxTickets = props.session.availableTicketCount;
  }

  const optionItems: JSX.Element[] = [];
  for (var i = 1; i <= maxTickets; i++) {
    optionItems.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div>
        <Form
          onSubmit={(values) => {
            let data = {
              discountOid: values.discountOid ? values.discountOid : null,
              id: values.id,
              name: values.name,
              email: values.email,
              phone: values.phone,
              ticketCount: values.ticketCount ? Number(values.ticketCount) : 0,
            };
            props.postTransaction(data, props.session.oid);
            props.setModal(false);
          }}
          render={({ handleSubmit, form, submitting, valid, values }) => (
            <form onSubmit={handleSubmit} className="needs-validation" >
              <div className="modal-header">
                <h5 className="modal-title" id="reservaModalLabel">
                  Nova reserva
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    form.reset();
                    props.setModal(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {props.session.activityName !== "" && (
                  <h6 className="blue2 mb-2">{props.session.activityName}</h6>
                )}
                <p>
                  Dia i hora:{" "}
                  {new Date(props.session?.startOn).toLocaleDateString(
                    "ca-ca",
                    {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    }
                  )}{" "}
                  {props.session?.endOn ? " de " : " a les "}
                  {new Date(props.session?.startOn).toLocaleTimeString(
                    // navigator.language,
                    "ca-ca",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                  {props.session?.endOn
                    ? " a " +
                      new Date(props.session?.endOn).toLocaleTimeString(
                        // navigator.language,
                        "ca-ca",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )
                    : null}
                </p>
                {props.session?.canBuyTicketsWithoutDiscount && (
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01"
                        id="ticketTooltip"
                      >
                        Núm. tiquets
                      </label>
                      <Tooltip
                        placement="top"
                        isOpen={ticketTooltipOpen}
                        target="ticketTooltip"
                        toggle={ticketToggle}
                        hideArrow={true}
                        style={{ backgroundColor: "#7c7c7c" }}
                      >
                        Número de tiquets que es volen reservar.
                      </Tooltip>
                    </div>
                    <Field
                      data-testid="ticketCountField"
                      name="ticketCount"
                      id="ticketTooltip"
                      component="select"
                      className="form-control"
                      onChange={(e: any) => {
                        form.change("ticketCount", e.target.value);
                      }}
                    >
                      <option>Seleccioni una quantitat</option>
                      {optionItems}
                    </Field>
                    <div className="invalid-feedback">
                      {form.getFieldState("ticketCount") &&
                        form.getFieldState("ticketCount")?.error}
                    </div>
                  </div>
                )}
                {!props.session?.canBuyTicketsWithoutDiscount &&
                  props.discount &&
                  props.discount.length > 0 && (
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                          id="ticketTooltip"
                        >
                          Núm. tiquets
                        </label>
                        <Tooltip
                          placement="top"
                          isOpen={ticketTooltipOpen}
                          target="ticketTooltip"
                          toggle={ticketToggle}
                          hideArrow={true}
                          style={{ backgroundColor: "#7c7c7c" }}
                        >
                          Número de tiquets que es volen reservar.
                        </Tooltip>
                      </div>
                      <Field
                        data-testid="discountOidField"
                        id="ticketTooltip"
                        name="discountOid"
                        component="select"
                        className="form-control"
                        onChange={(e: any) => {
                          form.change("discountOid", e.target.value);
                        }}
                      >
                        <option>Seleccioni una quantitat</option>
                        {props.discount.map((discount: any, i: any) => (
                          <option key={i} value={discount.oid}>
                            {discount.name +
                              " " +
                              discount.requiredTicketsNumber}
                          </option>
                        ))}
                      </Field>
                      <div className="invalid-feedback">
                        {form.getFieldState("discountOid") &&
                          form.getFieldState("discountOid")?.error}
                      </div>
                    </div>
                  )}
                <div className="form-group">
                  <label
                    htmlFor="recipient-name"
                    className="col-form-label"
                    id="nameTooltip"
                  >
                    Nom i cognoms
                  </label>
                  <Field
                    data-testid="nameField"
                    id="nameTooltip"
                    name="name"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={nameTooltipOpen}
                    target="nameTooltip"
                    toggle={nameToggle}
                    hideArrow={true}
                    style={{ backgroundColor: "#7c7c7c" }}
                  >
                    Nom i cognoms del responsable de la reserva.
                  </Tooltip>
                  <div className="invalid-feedback">
                    {form.getFieldState("name") &&
                      form.getFieldState("name")?.error}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="message-text"
                    className="col-form-label"
                    id="idTooltip"
                  >
                    DNI
                  </label>
                  <Field
                    data-testid="idField"
                    id="idTooltip"
                    name="id"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={idTooltipOpen}
                    target="idTooltip"
                    toggle={idToggle}
                    hideArrow={true}
                    style={{ backgroundColor: "#7c7c7c" }}
                  >
                    DNI del responsable de la reserva.
                  </Tooltip>
                  <div className="invalid-feedback">
                    {form.getFieldState("id") &&
                      form.getFieldState("id")?.error}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="email-text"
                    className="col-form-label"
                    id="emailTooltip"
                  >
                    Correu electrònic
                  </label>
                  <Field
                    data-testid="emailField"
                    id="emailTooltip"
                    name="email"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={emailTooltipOpen}
                    target="emailTooltip"
                    toggle={emailToggle}
                    hideArrow={true}
                    style={{ backgroundColor: "#7c7c7c" }}
                  >
                    Adreça electrònica del responsable de la reserva. Un cop
                    formalitzada la reserva, rebreu un correu amb les dades de
                    la mateixa.
                  </Tooltip>
                  <div className="invalid-feedback">
                    {form.getFieldState("email") &&
                      form.getFieldState("email")?.error}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="phone-text"
                    className="col-form-label"
                    id="phoneTooltip"
                  >
                    Telèfon
                  </label>
                  <Field
                    data-testid="phoneField"
                    id="phoneTooltip"
                    name="phone"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={phoneTooltipOpen}
                    target="phoneTooltip"
                    toggle={phoneToggle}
                    hideArrow={true}
                    style={{ backgroundColor: "#7c7c7c" }}
                  >
                    Telèfon del responsable de la reserva, a efectes de poder
                    posar-nos en contacte amb vosaltres en cas que hi hagués
                    alguna incidència.
                  </Tooltip>
                  <div className="invalid-feedback">
                    {form.getFieldState("phone") &&
                      form.getFieldState("phone")?.error}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      form.reset();
                      props.setModal(false);
                    }}
                  >
                    Cancel·lar
                  </button>
                  <button
                    data-testid="form"
                    type="submit"
                    className="btn btn-primary"
                    disabled={submitting || props.submittingForm}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (valid) {
                        props.setSubmittingForm(true);
                        form.submit();
                      }
                    }}
                  >
                    Entrar
                  </button>
                </div>
              </div>
            </form>
          )}
        />
    </div>
  );
};

export default VenueBookingModal;
