import { Configuration } from "../api";
import * as Constants from "./constants";
var jwt = require("jsonwebtoken");

export const getToken = () => {
  let token = sessionStorage.getItem(Constants.tokenKey)?.toString();
  return "bearer " + token;
};

export const setToken = (tokenKey: string) => {
  sessionStorage.setItem(Constants.tokenKey, tokenKey);
};

export const validateToken = () => {
  let token = sessionStorage.getItem(Constants.tokenKey);
  if (token == null) return false;
  let decodedToken = jwt.decode(token, { complete: true });
  let isExpired = decodedToken?.exp >= new Date().getTime() / 1000;
  return !isExpired;
};

export const getDefaultConfiguration = () => {
  return new Configuration({ apiKey: getToken() });
};
