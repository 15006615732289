/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivitySessionDto,
    ActivitySessionDtoFromJSON,
    ActivitySessionDtoFromJSONTyped,
    ActivitySessionDtoToJSON,
    CustomError,
    CustomErrorFromJSON,
    CustomErrorFromJSONTyped,
    CustomErrorToJSON,
} from './';

/**
 * The response with a objects of type "T".
 * @export
 * @interface ActivitySessionDtoResponseItemViewModel
 */
export interface ActivitySessionDtoResponseItemViewModel {
    /**
     * Gets the list of errors.
     * @type {Array<CustomError>}
     * @memberof ActivitySessionDtoResponseItemViewModel
     */
    readonly errors?: Array<CustomError> | null;
    /**
     * 
     * @type {ActivitySessionDto}
     * @memberof ActivitySessionDtoResponseItemViewModel
     */
    item?: ActivitySessionDto;
}

export function ActivitySessionDtoResponseItemViewModelFromJSON(json: any): ActivitySessionDtoResponseItemViewModel {
    return ActivitySessionDtoResponseItemViewModelFromJSONTyped(json, false);
}

export function ActivitySessionDtoResponseItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionDtoResponseItemViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(CustomErrorFromJSON)),
        'item': !exists(json, 'item') ? undefined : ActivitySessionDtoFromJSON(json['item']),
    };
}

export function ActivitySessionDtoResponseItemViewModelToJSON(value?: ActivitySessionDtoResponseItemViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': ActivitySessionDtoToJSON(value.item),
    };
}


