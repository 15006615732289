/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessOperationPostDto,
    AccessOperationPostDtoFromJSON,
    AccessOperationPostDtoToJSON,
    ActivitySessionCheckInDtoResponseItemViewModel,
    ActivitySessionCheckInDtoResponseItemViewModelFromJSON,
    ActivitySessionCheckInDtoResponseItemViewModelToJSON,
    ActivitySessionDateLookupDtoResponseListViewModel,
    ActivitySessionDateLookupDtoResponseListViewModelFromJSON,
    ActivitySessionDateLookupDtoResponseListViewModelToJSON,
    ActivitySessionDtoResponseItemViewModel,
    ActivitySessionDtoResponseItemViewModelFromJSON,
    ActivitySessionDtoResponseItemViewModelToJSON,
    ActivitySessionListDtoResponseListViewModel,
    ActivitySessionListDtoResponseListViewModelFromJSON,
    ActivitySessionListDtoResponseListViewModelToJSON,
    ActivitySessionStateDtoResponseItemViewModel,
    ActivitySessionStateDtoResponseItemViewModelFromJSON,
    ActivitySessionStateDtoResponseItemViewModelToJSON,
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
} from '../models';

export interface CloseVenueRequest {
    sessionId: string;
}

export interface GetSessionRequest {
    sessionId: string;
}

export interface GetSessionDatesByVenueRequest {
    venueId: string;
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
}

export interface GetSessionsRequest {
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
}

export interface GetSessionsByVenueRequest {
    venueId: string;
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
    date?: string;
}

export interface OpenSessionRequest {
    sessionId: string;
}

export interface OpenVenueRequest {
    venueId: string;
}

export interface PostAccessOperationRequest {
    sessionId: string;
    accessOperationPostDto?: AccessOperationPostDto;
}

export interface PostSessionCheckInRequest {
    sessionId: string;
    code?: string;
}

export interface PostSessionCheckOutRequest {
    sessionId: string;
    code?: string;
}

export interface SubscribeToStateNotificationsRequest {
    sessionId: string;
}

/**
 * 
 */
export class ActivitySessionApi extends runtime.BaseAPI {

    /**
     * Close a Venue.
     */
    async closeVenueRaw(requestParameters: CloseVenueRequest): Promise<runtime.ApiResponse<ActivitySessionStateDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling closeVenue.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/close`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionStateDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Close a Venue.
     */
    async closeVenue(requestParameters: CloseVenueRequest): Promise<ActivitySessionStateDtoResponseItemViewModel> {
        const response = await this.closeVenueRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the specified session.
     */
    async getSessionRaw(requestParameters: GetSessionRequest): Promise<runtime.ApiResponse<ActivitySessionDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Gets the specified session.
     */
    async getSession(requestParameters: GetSessionRequest): Promise<ActivitySessionDtoResponseItemViewModel> {
        const response = await this.getSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the future sessions of a venue.
     */
    async getSessionDatesByVenueRaw(requestParameters: GetSessionDatesByVenueRequest): Promise<runtime.ApiResponse<ActivitySessionDateLookupDtoResponseListViewModel>> {
        if (requestParameters.venueId === null || requestParameters.venueId === undefined) {
            throw new runtime.RequiredError('venueId','Required parameter requestParameters.venueId was null or undefined when calling getSessionDatesByVenue.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/venues/{venueId}/sessions/dates`.replace(`{${"venueId"}}`, encodeURIComponent(String(requestParameters.venueId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionDateLookupDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Get the future sessions of a venue.
     */
    async getSessionDatesByVenue(requestParameters: GetSessionDatesByVenueRequest): Promise<ActivitySessionDateLookupDtoResponseListViewModel> {
        const response = await this.getSessionDatesByVenueRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the sessions available for the current user for today or the next 4 hours.
     */
    async getSessionsRaw(requestParameters: GetSessionsRequest): Promise<runtime.ApiResponse<ActivitySessionListDtoResponseListViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionListDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Gets the sessions available for the current user for today or the next 4 hours.
     */
    async getSessions(requestParameters: GetSessionsRequest): Promise<ActivitySessionListDtoResponseListViewModel> {
        const response = await this.getSessionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the future sessions of a venue.
     */
    async getSessionsByVenueRaw(requestParameters: GetSessionsByVenueRequest): Promise<runtime.ApiResponse<ActivitySessionListDtoResponseListViewModel>> {
        if (requestParameters.venueId === null || requestParameters.venueId === undefined) {
            throw new runtime.RequiredError('venueId','Required parameter requestParameters.venueId was null or undefined when calling getSessionsByVenue.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/venues/{venueId}/sessions`.replace(`{${"venueId"}}`, encodeURIComponent(String(requestParameters.venueId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionListDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Get the future sessions of a venue.
     */
    async getSessionsByVenue(requestParameters: GetSessionsByVenueRequest): Promise<ActivitySessionListDtoResponseListViewModel> {
        const response = await this.getSessionsByVenueRaw(requestParameters);
        return await response.value();
    }

    /**
     * Open a session.
     */
    async openSessionRaw(requestParameters: OpenSessionRequest): Promise<runtime.ApiResponse<ActivitySessionStateDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling openSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/open`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionStateDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Open a session.
     */
    async openSession(requestParameters: OpenSessionRequest): Promise<ActivitySessionStateDtoResponseItemViewModel> {
        const response = await this.openSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Open a Venue.
     */
    async openVenueRaw(requestParameters: OpenVenueRequest): Promise<runtime.ApiResponse<ActivitySessionStateDtoResponseItemViewModel>> {
        if (requestParameters.venueId === null || requestParameters.venueId === undefined) {
            throw new runtime.RequiredError('venueId','Required parameter requestParameters.venueId was null or undefined when calling openVenue.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/venues/{venueId}/open`.replace(`{${"venueId"}}`, encodeURIComponent(String(requestParameters.venueId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionStateDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Open a Venue.
     */
    async openVenue(requestParameters: OpenVenueRequest): Promise<ActivitySessionStateDtoResponseItemViewModel> {
        const response = await this.openVenueRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post an operation to a Venue.
     */
    async postAccessOperationRaw(requestParameters: PostAccessOperationRequest): Promise<runtime.ApiResponse<ActivitySessionStateDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling postAccessOperation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/accessoperation`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessOperationPostDtoToJSON(requestParameters.accessOperationPostDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionStateDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Post an operation to a Venue.
     */
    async postAccessOperation(requestParameters: PostAccessOperationRequest): Promise<ActivitySessionStateDtoResponseItemViewModel> {
        const response = await this.postAccessOperationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Checks in a code (for a ticket or transaction) belonging to a session.
     */
    async postSessionCheckInRaw(requestParameters: PostSessionCheckInRequest): Promise<runtime.ApiResponse<ActivitySessionCheckInDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling postSessionCheckIn.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/checkin`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionCheckInDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Checks in a code (for a ticket or transaction) belonging to a session.
     */
    async postSessionCheckIn(requestParameters: PostSessionCheckInRequest): Promise<ActivitySessionCheckInDtoResponseItemViewModel> {
        const response = await this.postSessionCheckInRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check outs a code (for a ticket or transaction).
     */
    async postSessionCheckOutRaw(requestParameters: PostSessionCheckOutRequest): Promise<runtime.ApiResponse<ActivitySessionCheckInDtoResponseItemViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling postSessionCheckOut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/checkout`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivitySessionCheckInDtoResponseItemViewModelFromJSON(jsonValue));
    }

    /**
     * Check outs a code (for a ticket or transaction).
     */
    async postSessionCheckOut(requestParameters: PostSessionCheckOutRequest): Promise<ActivitySessionCheckInDtoResponseItemViewModel> {
        const response = await this.postSessionCheckOutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Subscribe to an ActivitySession web notifications.
     */
    async subscribeToStateNotificationsRaw(requestParameters: SubscribeToStateNotificationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling subscribeToStateNotifications.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sessions/{sessionId}/notifications`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribe to an ActivitySession web notifications.
     */
    async subscribeToStateNotifications(requestParameters: SubscribeToStateNotificationsRequest): Promise<void> {
        await this.subscribeToStateNotificationsRaw(requestParameters);
    }

}
