/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * CustomFieldValue POST data transfer object.
 * @export
 * @interface CustomFieldValuePostDto
 */
export interface CustomFieldValuePostDto {
    /**
     * Gets or sets the custom field Oid.
     * @type {string}
     * @memberof CustomFieldValuePostDto
     */
    customField?: string;
    /**
     * Gets or sets an identifier of the ticket.
     * @type {number}
     * @memberof CustomFieldValuePostDto
     */
    ticketId?: number;
    /**
     * Gets or sets the custom field value.
     * @type {string}
     * @memberof CustomFieldValuePostDto
     */
    value?: string | null;
}

export function CustomFieldValuePostDtoFromJSON(json: any): CustomFieldValuePostDto {
    return CustomFieldValuePostDtoFromJSONTyped(json, false);
}

export function CustomFieldValuePostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValuePostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customField': !exists(json, 'customField') ? undefined : json['customField'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function CustomFieldValuePostDtoToJSON(value?: CustomFieldValuePostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customField': value.customField,
        'ticketId': value.ticketId,
        'value': value.value,
    };
}


