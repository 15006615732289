/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessOperation {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function AccessOperationFromJSON(json: any): AccessOperation {
    return AccessOperationFromJSONTyped(json, false);
}

export function AccessOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessOperation {
    return json as AccessOperation;
}

export function AccessOperationToJSON(value?: AccessOperation | null): any {
    return value as any;
}

