/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOperation,
    AccessOperationFromJSON,
    AccessOperationFromJSONTyped,
    AccessOperationToJSON,
} from './';

/**
 * AccessLog list data transfer object.
 * @export
 * @interface AccessLogListDto
 */
export interface AccessLogListDto {
    /**
     * Gets or sets date.
     * @type {Date}
     * @memberof AccessLogListDto
     */
    date?: Date;
    /**
     * 
     * @type {AccessOperation}
     * @memberof AccessLogListDto
     */
    operation?: AccessOperation;
    /**
     * Gets or sets current count.
     * @type {number}
     * @memberof AccessLogListDto
     */
    currentCount?: number;
    /**
     * Gets or sets user full name.
     * @type {string}
     * @memberof AccessLogListDto
     */
    userFullName?: string | null;
    /**
     * Gets or sets the name of the person who is accessing.
     * @type {string}
     * @memberof AccessLogListDto
     */
    transactionPersonName?: string | null;
}

export function AccessLogListDtoFromJSON(json: any): AccessLogListDto {
    return AccessLogListDtoFromJSONTyped(json, false);
}

export function AccessLogListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessLogListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'operation': !exists(json, 'operation') ? undefined : AccessOperationFromJSON(json['operation']),
        'currentCount': !exists(json, 'currentCount') ? undefined : json['currentCount'],
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'transactionPersonName': !exists(json, 'transactionPersonName') ? undefined : json['transactionPersonName'],
    };
}

export function AccessLogListDtoToJSON(value?: AccessLogListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'operation': AccessOperationToJSON(value.operation),
        'currentCount': value.currentCount,
        'userFullName': value.userFullName,
        'transactionPersonName': value.transactionPersonName,
    };
}


