/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * User data transfer object.
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * Gets or sets user email / username.
     * @type {string}
     * @memberof UserDto
     */
    email?: string | null;
    /**
     * Gets or sets firstName.
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string | null;
    /**
     * Gets or sets lastName.
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string | null;
    /**
     * Gets or sets full name.
     * @type {string}
     * @memberof UserDto
     */
    fullName?: string | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'fullName': value.fullName,
    };
}


