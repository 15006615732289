/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldValueDto,
    CustomFieldValueDtoFromJSON,
    CustomFieldValueDtoFromJSONTyped,
    CustomFieldValueDtoToJSON,
    TransactionStatus,
    TransactionStatusFromJSON,
    TransactionStatusFromJSONTyped,
    TransactionStatusToJSON,
} from './';

/**
 * Channel data transfer object.
 * @export
 * @interface TransactionDto
 */
export interface TransactionDto {
    /**
     * Gets or sets the name of the transaction code.
     * @type {string}
     * @memberof TransactionDto
     */
    transactionCode?: string | null;
    /**
     * Gets or sets the date and time of the transaction.
     * @type {Date}
     * @memberof TransactionDto
     */
    transactionDate?: Date | null;
    /**
     * Gets or sets the date of the transaction.
     * @type {string}
     * @memberof TransactionDto
     */
    transactionDateDate?: string | null;
    /**
     * Gets or sets the time of the transaction.
     * @type {string}
     * @memberof TransactionDto
     */
    transactionDateTime?: string | null;
    /**
     * Gets or sets the name of the organization.
     * @type {string}
     * @memberof TransactionDto
     */
    organizationName?: string | null;
    /**
     * Gets or sets the name of the venue.
     * @type {string}
     * @memberof TransactionDto
     */
    venueName?: string | null;
    /**
     * Gets or sets the address of the venue.
     * @type {string}
     * @memberof TransactionDto
     */
    venueAddress?: string | null;
    /**
     * Gets or sets the postal code of the venue.
     * @type {string}
     * @memberof TransactionDto
     */
    venuePostalCode?: string | null;
    /**
     * Gets or sets the city of the venue.
     * @type {string}
     * @memberof TransactionDto
     */
    venueCity?: string | null;
    /**
     * Gets or sets the city of the venue.
     * @type {string}
     * @memberof TransactionDto
     */
    venuePhone?: string | null;
    /**
     * Gets or sets venue map url.
     * @type {string}
     * @memberof TransactionDto
     */
    venueMapUrl?: string | null;
    /**
     * Gets or sets the name of the activity.
     * @type {string}
     * @memberof TransactionDto
     */
    activityName?: string | null;
    /**
     * Gets or sets the date and time when the session starts.
     * @type {Date}
     * @memberof TransactionDto
     */
    activitySessionStartOn?: Date | null;
    /**
     * Gets or sets the date when the session starts.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionStartOnDate?: string | null;
    /**
     * Gets or sets the time when the session starts.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionStartOnTime?: string | null;
    /**
     * Gets or sets the date and time when the session ends.
     * @type {Date}
     * @memberof TransactionDto
     */
    activitySessionEndOn?: Date | null;
    /**
     * Gets or sets the date when the session ends.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionEndOnDate?: string | null;
    /**
     * Gets or sets the time when the session ends.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionEndOnTime?: string | null;
    /**
     * Gets or sets the number of tickets associated with this transaction.
     * @type {number}
     * @memberof TransactionDto
     */
    ticketsCount?: number | null;
    /**
     * Gets or sets the name of the discount.
     * @type {string}
     * @memberof TransactionDto
     */
    discountName?: string | null;
    /**
     * Gets or sets the id of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    id?: string | null;
    /**
     * Gets or sets the name of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    name?: string | null;
    /**
     * Gets or sets the email of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    email?: string | null;
    /**
     * Gets or sets the phone of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    phone?: string | null;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof TransactionDto
     */
    status?: TransactionStatus;
    /**
     * Gets or sets the label of the activity session the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionLabel?: string | null;
    /**
     * Gets or sets the info field of the activity session the transaction belongs to.
     * @type {string}
     * @memberof TransactionDto
     */
    activitySessionInfo?: string | null;
    /**
     * Gets or sets the HTML response.
     * @type {string}
     * @memberof TransactionDto
     */
    htmlResponse?: string | null;
    /**
     * 
     * @type {Array<CustomFieldValueDto>}
     * @memberof TransactionDto
     */
    customFields?: Array<CustomFieldValueDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    readonly customFieldValuesAsText?: string | null;
}

export function TransactionDtoFromJSON(json: any): TransactionDto {
    return TransactionDtoFromJSONTyped(json, false);
}

export function TransactionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionCode': !exists(json, 'transactionCode') ? undefined : json['transactionCode'],
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (json['transactionDate'] === null ? null : new Date(json['transactionDate'])),
        'transactionDateDate': !exists(json, 'transactionDateDate') ? undefined : json['transactionDateDate'],
        'transactionDateTime': !exists(json, 'transactionDateTime') ? undefined : json['transactionDateTime'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'venueName': !exists(json, 'venueName') ? undefined : json['venueName'],
        'venueAddress': !exists(json, 'venueAddress') ? undefined : json['venueAddress'],
        'venuePostalCode': !exists(json, 'venuePostalCode') ? undefined : json['venuePostalCode'],
        'venueCity': !exists(json, 'venueCity') ? undefined : json['venueCity'],
        'venuePhone': !exists(json, 'venuePhone') ? undefined : json['venuePhone'],
        'venueMapUrl': !exists(json, 'venueMapUrl') ? undefined : json['venueMapUrl'],
        'activityName': !exists(json, 'activityName') ? undefined : json['activityName'],
        'activitySessionStartOn': !exists(json, 'activitySessionStartOn') ? undefined : (json['activitySessionStartOn'] === null ? null : new Date(json['activitySessionStartOn'])),
        'activitySessionStartOnDate': !exists(json, 'activitySessionStartOnDate') ? undefined : json['activitySessionStartOnDate'],
        'activitySessionStartOnTime': !exists(json, 'activitySessionStartOnTime') ? undefined : json['activitySessionStartOnTime'],
        'activitySessionEndOn': !exists(json, 'activitySessionEndOn') ? undefined : (json['activitySessionEndOn'] === null ? null : new Date(json['activitySessionEndOn'])),
        'activitySessionEndOnDate': !exists(json, 'activitySessionEndOnDate') ? undefined : json['activitySessionEndOnDate'],
        'activitySessionEndOnTime': !exists(json, 'activitySessionEndOnTime') ? undefined : json['activitySessionEndOnTime'],
        'ticketsCount': !exists(json, 'ticketsCount') ? undefined : json['ticketsCount'],
        'discountName': !exists(json, 'discountName') ? undefined : json['discountName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'status': !exists(json, 'status') ? undefined : TransactionStatusFromJSON(json['status']),
        'activitySessionLabel': !exists(json, 'activitySessionLabel') ? undefined : json['activitySessionLabel'],
        'activitySessionInfo': !exists(json, 'activitySessionInfo') ? undefined : json['activitySessionInfo'],
        'htmlResponse': !exists(json, 'htmlResponse') ? undefined : json['htmlResponse'],
        'customFields': !exists(json, 'customFields') ? undefined : (json['customFields'] === null ? null : (json['customFields'] as Array<any>).map(CustomFieldValueDtoFromJSON)),
        'customFieldValuesAsText': !exists(json, 'customFieldValuesAsText') ? undefined : json['customFieldValuesAsText'],
    };
}

export function TransactionDtoToJSON(value?: TransactionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionCode': value.transactionCode,
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate === null ? null : value.transactionDate.toISOString()),
        'transactionDateDate': value.transactionDateDate,
        'transactionDateTime': value.transactionDateTime,
        'organizationName': value.organizationName,
        'venueName': value.venueName,
        'venueAddress': value.venueAddress,
        'venuePostalCode': value.venuePostalCode,
        'venueCity': value.venueCity,
        'venuePhone': value.venuePhone,
        'venueMapUrl': value.venueMapUrl,
        'activityName': value.activityName,
        'activitySessionStartOn': value.activitySessionStartOn === undefined ? undefined : (value.activitySessionStartOn === null ? null : value.activitySessionStartOn.toISOString()),
        'activitySessionStartOnDate': value.activitySessionStartOnDate,
        'activitySessionStartOnTime': value.activitySessionStartOnTime,
        'activitySessionEndOn': value.activitySessionEndOn === undefined ? undefined : (value.activitySessionEndOn === null ? null : value.activitySessionEndOn.toISOString()),
        'activitySessionEndOnDate': value.activitySessionEndOnDate,
        'activitySessionEndOnTime': value.activitySessionEndOnTime,
        'ticketsCount': value.ticketsCount,
        'discountName': value.discountName,
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'status': TransactionStatusToJSON(value.status),
        'activitySessionLabel': value.activitySessionLabel,
        'activitySessionInfo': value.activitySessionInfo,
        'htmlResponse': value.htmlResponse,
        'customFields': value.customFields === undefined ? undefined : (value.customFields === null ? null : (value.customFields as Array<any>).map(CustomFieldValueDtoToJSON)),
    };
}


