/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivitySessionListDto,
    ActivitySessionListDtoFromJSON,
    ActivitySessionListDtoFromJSONTyped,
    ActivitySessionListDtoToJSON,
    CustomError,
    CustomErrorFromJSON,
    CustomErrorFromJSONTyped,
    CustomErrorToJSON,
} from './';

/**
 * The response with a list of objects of type "T".
 * @export
 * @interface ActivitySessionListDtoResponseListViewModel
 */
export interface ActivitySessionListDtoResponseListViewModel {
    /**
     * Gets the list of errors.
     * @type {Array<CustomError>}
     * @memberof ActivitySessionListDtoResponseListViewModel
     */
    readonly errors?: Array<CustomError> | null;
    /**
     * Gets or sets the list of objects.
     * @type {Array<ActivitySessionListDto>}
     * @memberof ActivitySessionListDtoResponseListViewModel
     */
    data?: Array<ActivitySessionListDto> | null;
}

export function ActivitySessionListDtoResponseListViewModelFromJSON(json: any): ActivitySessionListDtoResponseListViewModel {
    return ActivitySessionListDtoResponseListViewModelFromJSONTyped(json, false);
}

export function ActivitySessionListDtoResponseListViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionListDtoResponseListViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(CustomErrorFromJSON)),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ActivitySessionListDtoFromJSON)),
    };
}

export function ActivitySessionListDtoResponseListViewModelToJSON(value?: ActivitySessionListDtoResponseListViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ActivitySessionListDtoToJSON)),
    };
}


