/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessLogListDtoResponseListViewModel,
    AccessLogListDtoResponseListViewModelFromJSON,
    AccessLogListDtoResponseListViewModelToJSON,
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
} from '../models';

export interface GetAccessLogsRequest {
    sessionId: string;
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
}

/**
 * 
 */
export class AccessLogApi extends runtime.BaseAPI {

    /**
     * Get an acces log list.
     */
    async getAccessLogsRaw(requestParameters: GetAccessLogsRequest): Promise<runtime.ApiResponse<AccessLogListDtoResponseListViewModel>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getAccessLogs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // oauth2 authentication
        }

        const response = await this.request({
            path: `/sessions/{sessionId}/accesslogs`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessLogListDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Get an acces log list.
     */
    async getAccessLogs(requestParameters: GetAccessLogsRequest): Promise<AccessLogListDtoResponseListViewModel> {
        const response = await this.getAccessLogsRaw(requestParameters);
        return await response.value();
    }

}
