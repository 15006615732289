import React from "react";
import {useState, useEffect} from "react";

// API
import * as VenueApi from "../../api/apis/VenueApi";
import * as ActivitySessionApi from "../../api/apis/ActivitySessionApi";
import { VenueDto } from "../../api/models/VenueDto";

// Components
import VenueStatusComponent from "../../components/Venues/OpenVenueList";

import * as Utils from "../../utils";
import * as Constants from "../../utils/constants";

const VenueStatusLayout: React.FC<any> = (props: any) => {
  const [openedVenues, setOpenedVenues] = useState<VenueDto[]>([]);
  const [time, setTime] = useState<number>(Date.now());
  const [refresh, setRefresh] = useState<number>();

  // Refresh page
  useEffect(() => {
      let interval = setInterval(
        () => setTime(Date.now()),
        Constants.refreshInterval
      );
      return () => {
        clearInterval(interval);
      };
    }, []);

  // Get Venues
  useEffect(() => {
      let venueApi = new VenueApi.VenueApi(Utils.getDefaultConfiguration());
  
      venueApi
        .getVenues({})
        .then((response: any) => {
          setOpenedVenues((openedVenues: VenueDto[]) => 
            openedVenues = response.data.filter((venue: VenueDto) => venue.currentActivitySession != null)
          );
        })
        .catch((response) => {
          Utils.showError(props.setAlert, response);
        });
    }, [time, refresh]);

    const closeVenue = (sessionId: string) => {
      new ActivitySessionApi.ActivitySessionApi(Utils.getDefaultConfiguration())
        .closeVenue({
          sessionId: sessionId,
        })
        .then(() => setRefresh(new Date().getTime()))
        .catch((response) => {
            console.log(props)
          Utils.showError(props.setAlert, response);
        });
    };

  return (
      <div>
          <VenueStatusComponent 
              openedVenues={openedVenues} 
              closeVenue={closeVenue}
              {...props}
          />
      </div>
  );
};

export default VenueStatusLayout;