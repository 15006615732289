import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const VenueDetailHistory: React.FC<any> = (props: any) => {
  let history = useHistory();
  const [shortHistoryVisible, setShortHistoryVisible] =
    useState<boolean>(false);

  return (
    <div>
      <div className="tab-content pt-2 pl-1" id="pills-tabContent">
        {shortHistoryVisible && (
          <div
            className={
              props.venue.currentActivitySession &&
              props.venue.currentActivitySession?.ticketsRequired
                ? "tab-pane fade p-2"
                : "tab-pane fade show active p-2"
            }
            id="pills-historial"
            role="tabpanel"
            aria-labelledby="pills-historial-tab"
          >
            <table
              className={
                !props.nightShift ||
                props.venue.currentActivitySession?.occupancy >=
                  props.venue.currentActivitySession?.capacity
                  ? "table operations-table"
                  : "table table-white operations-table"
              }
            >
              <tbody>
                {props.logs &&
                  props.logs
                    .filter((log: any, i: any) => i < 3)
                    .map((log: any, i: any) => {
                      return (
                        <tr key={i}>
                          <td style={{ width: "4em" }}>
                            <strong>
                              {new Date(log.date).toLocaleTimeString(
                                navigator.language,
                                {
                                  hour: "2-digit",
                                  hour12: false,
                                  minute: "2-digit",
                                }
                              )}
                            </strong>
                          </td>
                          {props.venue.currentActivitySession
                            ?.ticketsRequired ? (
                            <td>{log.transactionPersonName}</td>
                          ) : null}
                          <td style={{ width: "6em" }}>
                            <strong>
                              {log.operation === 1 ? "Entrada" : "Sortida"}
                            </strong>
                          </td>
                          <td style={{ width: "4em" }}>{log.currentCount}</td>
                          <td>{log.userFullName}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <br />
      {props.logs?.length !== 0 && (
        <div className="row justify-content-center pl-4 mb-4">
          <button
            className={
              !props.nightShift ||
              props.venue.currentActivitySession?.occupancy >=
                props.venue.currentActivitySession?.capacity
                ? "btn btn-sm btn-outline-light px-3 mb-2 mr-4 rounded-button"
                : "btn btn-sm btn-outline-white px-3 mb-2 mr-4 rounded-button"
            }
            onClick={() => setShortHistoryVisible(!shortHistoryVisible)}
          >
            {shortHistoryVisible ? (
              <div>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 1 16 16"
                  className="bi bi-eye-slash"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                  <path
                    fill-rule="evenodd"
                    d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                  />
                </svg>{" "}
                Oculta historial
              </div>
            ) : (
              <div>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 1 16 16"
                  className="bi bi-eye"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  />
                </svg>{" "}
                Mostra historial
              </div>
            )}
          </button>

          <button
            className={
              !props.nightShift ||
              props.venue.currentActivitySession?.occupancy >=
                props.venue.currentActivitySession?.capacity
                ? "btn btn-sm btn-outline-light px-3 mb-2 mr-4 rounded-button"
                : "btn btn-sm btn-outline-white px-3 mb-2 mr-4 rounded-button"
            }
            onClick={() =>
              history.push("/venuedetail/" + props.venue.oid + "/history")
            }
          >
            <div>
              <svg
                width="1em"
                height="1em"
                viewBox="0 1 16 16"
                className="bi bi-clock-history"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"
                />
                <path
                  fill-rule="evenodd"
                  d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                />
              </svg>{" "}
              Veure tot
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default VenueDetailHistory;
