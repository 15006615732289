import React from "react";
import VenueItemComponent from "./VenueItem";

const VenueList: React.FC<any> = (props: any) => {
  return (
    <div>
      <main role="main" className="container pt-2 pt-lg-3">
        <div className="row justify-content-center p-3">
          <div className="col col-lg-6 col-md-12 col-sm-12 col-12 p-0">
            <div className="text-center">
              <h1 className="medium-title">Gestió d'ocupació de recintes</h1>
            </div>
            {props.venues &&
              props.venues.map((venue: any) => {
                return (
                  <div key={venue.oid}>
                    {" "}
                    <VenueItemComponent venue={venue} />{" "}
                  </div>
                );
              })}
          </div>
        </div>
      </main>
    </div>
  );
};

export default VenueList;
