import React from "react";

const CloseVenueModal: React.FC<any> = (props: any) => {
    return (
        <div>
            <div className="modal-header">
                <strong style={{color: "#e22f03"}}>
                    <svg width="1.0625em" height="1em" viewBox="0 1 17 16" className="bi bi-exclamation-triangle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    </svg> Tancar recinte
                </strong>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                    props.setModal(false);
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="row">
                    <div className="col-3 py-0 pl-2 pr-0">
                        <img
                            src={props.venue?.imageUrl}
                            alt={props.venue?.name}
                            className="mr-3 img-fluid rounded mx-auto d-block border"
                        />
                    </div>
                    <span className="col-9 mb-0 small lh-125  ">
                        <strong className="d-block small-title">{props.venue?.name}</strong>
                        <span className="medium-title mb-0" style={{fontSize: "24pt"}}>
                            <span className="counter-reserves">
                                {props.venue.currentActivitySession?.occupancy}
                            </span> 
                            /{props.venue.currentActivitySession?.capacity}
                        </span>       
                    </span>
                </div>
                <br/>
                Estàs segur que vols tancar el recinte i posar <strong style={{color: "#e22f03"}}>el comptador a zero?</strong>
            </div>

            <div className="modal-footer">
                <button
                    className="btn btn-outline-light px-3 mb-2 mr-4 rounded-button"
                    onClick={() => props.setModal(!props.modal)}
                    style={{color: "#6993a9"}}
                >
                    No, cancel·lar    
                </button>
                <button
                    className="btn btn-outline-light px-3 mb-2 mr-4 rounded-button"
                    onClick={() => {
                        props.closeVenue(props.venue.currentActivitySession.oid)
                        props.setModal(!props.modal)
                    }}
                    style={{color: "#6993a9"}}
                >
                    Sí, tancar accés  
                </button>
            </div>
        </div>
    )
};

export default CloseVenueModal;