import React from "react";
import { Form, Field } from "react-final-form";
import Alert from "../Common/Alert";

const Login: React.FC<any> = (props: any) => {
  return (
    <div className="container">
      <Alert {...props}/>
      <div className="row justify-content-center text-center">
        <div className="col col-10 col-md-7 col-lg-4 col-xl-3 pt-5">
          <Form 
            onSubmit={(values) => props.loginPost(values)}
            render={({ handleSubmit }) => (
              <form
                data-testid="form"
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
              >
                <img
                  className="mb-4 mt-0 mt-xl-4"
                  src={require("../../assets/img/Logo_Recintes_Dotze_de_juny.png")}
                  alt="Logotip aforament recintes"
                  height="72"
                />
                <p>
                  Controla <b>l'aforament</b> dels teus recintes, informa{" "}
                  <b>en temps real</b>
                </p>

                <label htmlFor="inputEmail" className="sr-only">
                  Correu electrònic
                </label>
                <Field
                  type="email"
                  name="email"
                  component="input"
                  className="form-control "
                  placeholder="Correu electrònic"
                  required
                  autoFocus
                />
                <div className="invalid-feedback text-left">
                  Omple aquest camp per continuar. <br></br>
                  No hem pogut trobar cap compte amb aquest correu electrònic.
                </div>

                <div className="mt-2">
                  <label htmlFor="inputPassword" className="sr-only ">
                    Contrasenya
                  </label>
                  <Field
                    type="password"
                    name="password"
                    component="input"
                    className="form-control "
                    placeholder="Contrasenya"
                    required
                  />
                  <div className="invalid-feedback text-left">
                    Omple aquest camp per continuar. Contrasenya incorrecta.
                    Recorda que pots establir una nova contrasenya amb el correu
                    electrònic si es necessari.<br></br>
                  </div>
                </div>

                <div className="invalid-feedback text-left">
                  S'ha produït un error inesperat. Si us plau, torna a
                  intentar-ho, o posa't en contacte amb nosaltres i t'ajudarem a
                  resoldre la incidència.
                </div>

                <div className="row justify-content-center text-center mt-3">
                  <div className="col col-6">
                    <button
                      className="btn btn-md btn-primary btn-block mb-2 rounded-button"
                      type="submit"
                    >
                      Accedir
                    </button>
                  </div>
                </div>

                {/* <small>
                  No recordes la contrasenya? <a href="#">Canvia-la</a>
                </small> */}
              </form>
            )}
          />

          {/* <div className="dropdown show">
            <a
              className="btn btn-sm btn-default border mt-3 dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Català
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a className="dropdown-item" href="#">
                Català
              </a>
              <a className="dropdown-item" href="#">
                Castellà
              </a>
              <a className="dropdown-item" href="#">
                Anglès
              </a>
            </div>
          </div> */}

          <div className="col col-auto"></div>
        </div>
      </div>

      <footer className="footer mt-auto ">
        <div className="container text-center">
          <div className="row justify-content-center text-center">
            <div className="col col-12 col-md-10 col-lg-8 pt-1">
              <img
                src={require("../../assets/img/bg_recintes.png")}
                alt="Imatge explicativa del funcionament de l'aplicació de recintes per ajuntaments"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;
