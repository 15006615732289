/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivitySessionStateDto,
    ActivitySessionStateDtoFromJSON,
    ActivitySessionStateDtoFromJSONTyped,
    ActivitySessionStateDtoToJSON,
    CustomError,
    CustomErrorFromJSON,
    CustomErrorFromJSONTyped,
    CustomErrorToJSON,
} from './';

/**
 * The response with a objects of type "T".
 * @export
 * @interface ActivitySessionStateDtoResponseItemViewModel
 */
export interface ActivitySessionStateDtoResponseItemViewModel {
    /**
     * Gets the list of errors.
     * @type {Array<CustomError>}
     * @memberof ActivitySessionStateDtoResponseItemViewModel
     */
    readonly errors?: Array<CustomError> | null;
    /**
     * 
     * @type {ActivitySessionStateDto}
     * @memberof ActivitySessionStateDtoResponseItemViewModel
     */
    item?: ActivitySessionStateDto;
}

export function ActivitySessionStateDtoResponseItemViewModelFromJSON(json: any): ActivitySessionStateDtoResponseItemViewModel {
    return ActivitySessionStateDtoResponseItemViewModelFromJSONTyped(json, false);
}

export function ActivitySessionStateDtoResponseItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionStateDtoResponseItemViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(CustomErrorFromJSON)),
        'item': !exists(json, 'item') ? undefined : ActivitySessionStateDtoFromJSON(json['item']),
    };
}

export function ActivitySessionStateDtoResponseItemViewModelToJSON(value?: ActivitySessionStateDtoResponseItemViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': ActivitySessionStateDtoToJSON(value.item),
    };
}


