/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldValuePostDto,
    CustomFieldValuePostDtoFromJSON,
    CustomFieldValuePostDtoFromJSONTyped,
    CustomFieldValuePostDtoToJSON,
} from './';

/**
 * Channel data transfer object.
 * @export
 * @interface TransactionPostDto
 */
export interface TransactionPostDto {
    /**
     * Gets or sets id of the selected Discount.
     * @type {string}
     * @memberof TransactionPostDto
     */
    discountOid?: string | null;
    /**
     * Gets or sets the id of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionPostDto
     */
    id?: string | null;
    /**
     * Gets or sets the name of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionPostDto
     */
    name?: string | null;
    /**
     * Gets or sets the email of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionPostDto
     */
    email?: string | null;
    /**
     * Gets or sets the phone of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionPostDto
     */
    phone?: string | null;
    /**
     * Gets or sets the postal code of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionPostDto
     */
    postalCode?: string | null;
    /**
     * Gets or sets a value indicating whether the person wants to receive marketing information or not.
     * @type {boolean}
     * @memberof TransactionPostDto
     */
    canSendInfo?: boolean;
    /**
     * Gets or sets a value of the quantity of tickets requested.
     * @type {number}
     * @memberof TransactionPostDto
     */
    ticketCount: number;
    /**
     * Gets or sets a Fila12.WebApi.ViewModels.Tickets.CustomFieldValuePostDto list.
     * @type {Array<CustomFieldValuePostDto>}
     * @memberof TransactionPostDto
     */
    customFields?: Array<CustomFieldValuePostDto> | null;
}

export function TransactionPostDtoFromJSON(json: any): TransactionPostDto {
    return TransactionPostDtoFromJSONTyped(json, false);
}

export function TransactionPostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountOid': !exists(json, 'discountOid') ? undefined : json['discountOid'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'canSendInfo': !exists(json, 'canSendInfo') ? undefined : json['canSendInfo'],
        'ticketCount': json['ticketCount'],
        'customFields': !exists(json, 'customFields') ? undefined : (json['customFields'] === null ? null : (json['customFields'] as Array<any>).map(CustomFieldValuePostDtoFromJSON)),
    };
}

export function TransactionPostDtoToJSON(value?: TransactionPostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountOid': value.discountOid,
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'postalCode': value.postalCode,
        'canSendInfo': value.canSendInfo,
        'ticketCount': value.ticketCount,
        'customFields': value.customFields === undefined ? undefined : (value.customFields === null ? null : (value.customFields as Array<any>).map(CustomFieldValuePostDtoToJSON)),
    };
}


