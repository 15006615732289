/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * UserLogin data transfer object.
 * @export
 * @interface UserLoginDto
 */
export interface UserLoginDto {
    /**
     * Gets or sets username.
     * @type {string}
     * @memberof UserLoginDto
     */
    email: string;
    /**
     * Gets or sets password.
     * @type {string}
     * @memberof UserLoginDto
     */
    password: string;
}

export function UserLoginDtoFromJSON(json: any): UserLoginDto {
    return UserLoginDtoFromJSONTyped(json, false);
}

export function UserLoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function UserLoginDtoToJSON(value?: UserLoginDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
    };
}


