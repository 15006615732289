/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ActivitySession data transfer object.
 * @export
 * @interface ActivitySessionListDto
 */
export interface ActivitySessionListDto {
    /**
     * Gets or sets the activitySession oid.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    oid?: string;
    /**
     * Gets or sets the startOn date.
     * @type {Date}
     * @memberof ActivitySessionListDto
     */
    startOn?: Date | null;
    /**
     * Gets or sets the startOn date part as string.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    startOnDate?: string | null;
    /**
     * Gets or sets the startOn time part as string.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    startOnTime?: string | null;
    /**
     * Gets or sets the endOn date and time of the session.
     * @type {Date}
     * @memberof ActivitySessionListDto
     */
    endOn?: Date | null;
    /**
     * Gets or sets the endOn date part as string.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    endOnDate?: string | null;
    /**
     * Gets or sets the endOn time part as string.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    endOnTime?: string | null;
    /**
     * Gets or sets a value indicating whether tickets can be bought in any quantity.
     * @type {boolean}
     * @memberof ActivitySessionListDto
     */
    canBuyTicketsWithoutDiscount?: boolean;
    /**
     * Gets or sets the maximum number of tickets that can be bought per transaction.
     * @type {number}
     * @memberof ActivitySessionListDto
     */
    maxTicketsPerTransaction?: number;
    /**
     * Gets or sets the number of tickets available in the session.
     * @type {number}
     * @memberof ActivitySessionListDto
     */
    availableTicketCount?: number;
    /**
     * Gets or sets the Oid of the activity.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    activityOid?: string;
    /**
     * Gets or sets the name of the activity.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    activityName?: string | null;
    /**
     * Gets or sets the total ticket count for the session.
     * @type {number}
     * @memberof ActivitySessionListDto
     */
    totalTicketCount?: number;
    /**
     * Gets or sets the checked in ticket count for the session.
     * @type {number}
     * @memberof ActivitySessionListDto
     */
    checkedInTicketCount?: number;
    /**
     * Gets or sets the label (short text) for the session.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    label?: string | null;
    /**
     * Gets or sets the unit name of the session's activity.
     * @type {string}
     * @memberof ActivitySessionListDto
     */
    unitName?: string | null;
    /**
     * Gets or sets a value indicating whether re-enter is allowed or not.
     * @type {boolean}
     * @memberof ActivitySessionListDto
     */
    reenterAllowed?: boolean;
}

export function ActivitySessionListDtoFromJSON(json: any): ActivitySessionListDto {
    return ActivitySessionListDtoFromJSONTyped(json, false);
}

export function ActivitySessionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'startOn': !exists(json, 'startOn') ? undefined : (json['startOn'] === null ? null : new Date(json['startOn'])),
        'startOnDate': !exists(json, 'startOnDate') ? undefined : json['startOnDate'],
        'startOnTime': !exists(json, 'startOnTime') ? undefined : json['startOnTime'],
        'endOn': !exists(json, 'endOn') ? undefined : (json['endOn'] === null ? null : new Date(json['endOn'])),
        'endOnDate': !exists(json, 'endOnDate') ? undefined : json['endOnDate'],
        'endOnTime': !exists(json, 'endOnTime') ? undefined : json['endOnTime'],
        'canBuyTicketsWithoutDiscount': !exists(json, 'canBuyTicketsWithoutDiscount') ? undefined : json['canBuyTicketsWithoutDiscount'],
        'maxTicketsPerTransaction': !exists(json, 'maxTicketsPerTransaction') ? undefined : json['maxTicketsPerTransaction'],
        'availableTicketCount': !exists(json, 'availableTicketCount') ? undefined : json['availableTicketCount'],
        'activityOid': !exists(json, 'activityOid') ? undefined : json['activityOid'],
        'activityName': !exists(json, 'activityName') ? undefined : json['activityName'],
        'totalTicketCount': !exists(json, 'totalTicketCount') ? undefined : json['totalTicketCount'],
        'checkedInTicketCount': !exists(json, 'checkedInTicketCount') ? undefined : json['checkedInTicketCount'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
        'reenterAllowed': !exists(json, 'reenterAllowed') ? undefined : json['reenterAllowed'],
    };
}

export function ActivitySessionListDtoToJSON(value?: ActivitySessionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'startOn': value.startOn === undefined ? undefined : (value.startOn === null ? null : value.startOn.toISOString()),
        'startOnDate': value.startOnDate,
        'startOnTime': value.startOnTime,
        'endOn': value.endOn === undefined ? undefined : (value.endOn === null ? null : value.endOn.toISOString()),
        'endOnDate': value.endOnDate,
        'endOnTime': value.endOnTime,
        'canBuyTicketsWithoutDiscount': value.canBuyTicketsWithoutDiscount,
        'maxTicketsPerTransaction': value.maxTicketsPerTransaction,
        'availableTicketCount': value.availableTicketCount,
        'activityOid': value.activityOid,
        'activityName': value.activityName,
        'totalTicketCount': value.totalTicketCount,
        'checkedInTicketCount': value.checkedInTicketCount,
        'label': value.label,
        'unitName': value.unitName,
        'reenterAllowed': value.reenterAllowed,
    };
}


