/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomFieldDtoResponseListViewModel,
    CustomFieldDtoResponseListViewModelFromJSON,
    CustomFieldDtoResponseListViewModelToJSON,
    ResponseViewModel,
    ResponseViewModelFromJSON,
    ResponseViewModelToJSON,
} from '../models';

export interface GetCustomFieldsRequest {
    activityId: string;
    pageNumber?: number;
    pageSize?: number;
    filter?: string;
    orderBy?: string;
}

/**
 * 
 */
export class ActivityApi extends runtime.BaseAPI {

    /**
     * Get the list of custom fields for the specified activity.
     */
    async getCustomFieldsRaw(requestParameters: GetCustomFieldsRequest): Promise<runtime.ApiResponse<CustomFieldDtoResponseListViewModel>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling getCustomFields.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['Filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/activities/{activityId}/customfields`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldDtoResponseListViewModelFromJSON(jsonValue));
    }

    /**
     * Get the list of custom fields for the specified activity.
     */
    async getCustomFields(requestParameters: GetCustomFieldsRequest): Promise<CustomFieldDtoResponseListViewModel> {
        const response = await this.getCustomFieldsRaw(requestParameters);
        return await response.value();
    }

}
