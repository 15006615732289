/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorCode,
    ErrorCodeFromJSON,
    ErrorCodeFromJSONTyped,
    ErrorCodeToJSON,
} from './';

/**
 * An error.
 * @export
 * @interface CustomError
 */
export interface CustomError {
    /**
     * Gets or sets the error message.
     * @type {string}
     * @memberof CustomError
     */
    message?: string | null;
    /**
     * 
     * @type {ErrorCode}
     * @memberof CustomError
     */
    code?: ErrorCode;
}

export function CustomErrorFromJSON(json: any): CustomError {
    return CustomErrorFromJSONTyped(json, false);
}

export function CustomErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : ErrorCodeFromJSON(json['code']),
    };
}

export function CustomErrorToJSON(value?: CustomError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'code': ErrorCodeToJSON(value.code),
    };
}


