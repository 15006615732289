import React from "react";

function VenueOccupancyBar(props: any) {
  const occupancyPercent: number =
    props.venue.capacity <= 0
      ? 0
      : Math.trunc(
          (props.venue.currentActivitySession?.occupancy * 100) /
            props.venue.currentActivitySession?.capacity
        );
  const classColor =
    occupancyPercent < props.venue.currentActivitySession?.mediumOccupancyLevel
      ? "bg-success"
      : occupancyPercent <
        props.venue.currentActivitySession?.highOccupancyLevel
      ? "bg-warning"
      : "bg-danger";

  return (
    <div
      className={"progress-bar progress-bar-striped " + classColor}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={occupancyPercent}
      style={{ width: occupancyPercent + "%" }}
    >
      <span className="sr-only">{occupancyPercent}% Complete</span>
    </div>
  );
}

export default VenueOccupancyBar;
