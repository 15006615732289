import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

//API
import { VenueDto } from "../../../api/models/VenueDto";

const VenueHistory: React.FC<any> = (props: any) => {
  let history = useHistory();
  const venue: VenueDto = props.venue;

  useEffect(() => {
    props.getFullAccessLogs(venue.currentActivitySession?.oid);
  }, []);

  return (
    <div>
      <main role="main" className="container pt-3">
        <div className="row justify-content-md-center p-3">
          <div className="col col-lg-6 col-md-12 col-sm-12 col-12 p-0 bg-white rounded shadow-sm">
            <div className="row justify-content-center text-center">
              <div className="col col-auto">
                <h1 className="big-title mt-4">{venue?.name}</h1>
                {venue.currentActivitySession?.activityName && (
                  <span className="row justify-content-center blue2">
                    {venue.currentActivitySession?.activityName}
                  </span>
                )}
                <button className="btn blue1" onClick={history.goBack}>
                  <svg
                    className="bi bi-arrow-left mr-2"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                  Tornar al recinte
                </button>
                <br />
                <br />
                <div
                  className={
                    props.venue.currentActivitySession &&
                    props.venue.currentActivitySession?.ticketsRequired
                      ? "tab-pane fade p-2"
                      : "tab-pane fade show active p-2"
                  }
                  id="pills-historial"
                  role="tabpanel"
                  aria-labelledby="pills-historial-tab"
                >
                  <table className="table operations-table">
                    <thead>
                      <h6 className="blue2 mb-0">
                        Historial d'accés{" "}
                        {new Date(props.fullLogs[0]?.date).toLocaleDateString()}
                      </h6>
                      <br />
                    </thead>
                    <tbody>
                      {props.fullLogs &&
                        props.fullLogs.map((log: any, i: any) => {
                          return (
                            <tr key={i}>
                              <td style={{ width: "4em" }}>
                                <strong>
                                  {new Date(log.date).toLocaleTimeString(
                                    navigator.language,
                                    {
                                      hour: "2-digit",
                                      hour12: false,
                                      minute: "2-digit",
                                    }
                                  )}
                                </strong>
                              </td>
                              {props.venue.currentActivitySession
                                ?.ticketsRequired ? (
                                <td>{log.transactionPersonName}</td>
                              ) : null}
                              <td style={{ width: "6em" }}>
                                <strong>
                                  {log.operation === 1 ? "Entrada" : "Sortida"}
                                </strong>
                              </td>
                              <td style={{ width: "4em" }}>
                                {log.currentCount}
                              </td>
                              <td>{log.userFullName}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default VenueHistory;
