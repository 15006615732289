/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TicketDto,
    TicketDtoFromJSON,
    TicketDtoFromJSONTyped,
    TicketDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivitySessionCheckInDto
 */
export interface ActivitySessionCheckInDto {
    /**
     * 
     * @type {Date}
     * @memberof ActivitySessionCheckInDto
     */
    transactionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySessionCheckInDto
     */
    transactionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySessionCheckInDto
     */
    transactionEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySessionCheckInDto
     */
    transactionPhone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivitySessionCheckInDto
     */
    transactionIsBoxOffice?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ActivitySessionCheckInDto
     */
    transactionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySessionCheckInDto
     */
    transactionTicketsCount?: number;
    /**
     * Gets or sets a Fila12.WebApi.ViewModels.Tickets.TicketDto list.
     * @type {Array<TicketDto>}
     * @memberof ActivitySessionCheckInDto
     */
    tickets?: Array<TicketDto> | null;
    /**
     * Gets or sets the total ticket count for the referenced session.
     * @type {number}
     * @memberof ActivitySessionCheckInDto
     */
    totalTicketCount?: number;
    /**
     * Gets or sets the checked in ticket count for the referenced session.
     * @type {number}
     * @memberof ActivitySessionCheckInDto
     */
    checkedInTicketCount?: number;
}

export function ActivitySessionCheckInDtoFromJSON(json: any): ActivitySessionCheckInDto {
    return ActivitySessionCheckInDtoFromJSONTyped(json, false);
}

export function ActivitySessionCheckInDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivitySessionCheckInDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (json['transactionDate'] === null ? null : new Date(json['transactionDate'])),
        'transactionName': !exists(json, 'transactionName') ? undefined : json['transactionName'],
        'transactionEmail': !exists(json, 'transactionEmail') ? undefined : json['transactionEmail'],
        'transactionPhone': !exists(json, 'transactionPhone') ? undefined : json['transactionPhone'],
        'transactionIsBoxOffice': !exists(json, 'transactionIsBoxOffice') ? undefined : json['transactionIsBoxOffice'],
        'transactionAmount': !exists(json, 'transactionAmount') ? undefined : json['transactionAmount'],
        'transactionTicketsCount': !exists(json, 'transactionTicketsCount') ? undefined : json['transactionTicketsCount'],
        'tickets': !exists(json, 'tickets') ? undefined : (json['tickets'] === null ? null : (json['tickets'] as Array<any>).map(TicketDtoFromJSON)),
        'totalTicketCount': !exists(json, 'totalTicketCount') ? undefined : json['totalTicketCount'],
        'checkedInTicketCount': !exists(json, 'checkedInTicketCount') ? undefined : json['checkedInTicketCount'],
    };
}

export function ActivitySessionCheckInDtoToJSON(value?: ActivitySessionCheckInDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate === null ? null : value.transactionDate.toISOString()),
        'transactionName': value.transactionName,
        'transactionEmail': value.transactionEmail,
        'transactionPhone': value.transactionPhone,
        'transactionIsBoxOffice': value.transactionIsBoxOffice,
        'transactionAmount': value.transactionAmount,
        'transactionTicketsCount': value.transactionTicketsCount,
        'tickets': value.tickets === undefined ? undefined : (value.tickets === null ? null : (value.tickets as Array<any>).map(TicketDtoToJSON)),
        'totalTicketCount': value.totalTicketCount,
        'checkedInTicketCount': value.checkedInTicketCount,
    };
}


