/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivitySessionDto,
    ActivitySessionDtoFromJSON,
    ActivitySessionDtoFromJSONTyped,
    ActivitySessionDtoToJSON,
} from './';

/**
 * Venue data transfer object.
 * @export
 * @interface VenueDto
 */
export interface VenueDto {
    /**
     * Gets or sets venue oid.
     * @type {string}
     * @memberof VenueDto
     */
    oid?: string;
    /**
     * Gets or sets venue name.
     * @type {string}
     * @memberof VenueDto
     */
    name?: string | null;
    /**
     * Gets or sets venue description.
     * @type {string}
     * @memberof VenueDto
     */
    description?: string | null;
    /**
     * Gets or sets venue address.
     * @type {string}
     * @memberof VenueDto
     */
    address?: string | null;
    /**
     * Gets or sets venue postal code.
     * @type {string}
     * @memberof VenueDto
     */
    postalCode?: string | null;
    /**
     * Gets or sets venue city.
     * @type {string}
     * @memberof VenueDto
     */
    city?: string | null;
    /**
     * Gets or sets venue phone.
     * @type {string}
     * @memberof VenueDto
     */
    phone?: string | null;
    /**
     * Gets or sets venue map url.
     * @type {string}
     * @memberof VenueDto
     */
    mapUrl?: string | null;
    /**
     * Gets or sets venue map url.
     * @type {string}
     * @memberof VenueDto
     */
    imageUrl?: string | null;
    /**
     * Gets or sets venue more info url.
     * @type {string}
     * @memberof VenueDto
     */
    moreInfoUrl?: string | null;
    /**
     * 
     * @type {ActivitySessionDto}
     * @memberof VenueDto
     */
    currentActivitySession?: ActivitySessionDto;
    /**
     * Gets or sets a value indicating whether the venue has any published session.
     * @type {boolean}
     * @memberof VenueDto
     */
    isPublished?: boolean;
    /**
     * Gets or sets a value indicating whether the venue is assigned to any Channel.
     * @type {boolean}
     * @memberof VenueDto
     */
    isVisible?: boolean;
    /**
     * Gets or sets a value indicating whether the end user should view the ActivitySession dates
     * to choose from.
     * @type {boolean}
     * @memberof VenueDto
     */
    chooseDateFirst?: boolean;
    /**
     * Gets or sets the available tickets count.
     * @type {number}
     * @memberof VenueDto
     */
    availableTicketsCount?: number;
    /**
     * Gets or sets the unit name.
     * @type {string}
     * @memberof VenueDto
     */
    unitName?: string | null;
}

export function VenueDtoFromJSON(json: any): VenueDto {
    return VenueDtoFromJSONTyped(json, false);
}

export function VenueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VenueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mapUrl': !exists(json, 'mapUrl') ? undefined : json['mapUrl'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'moreInfoUrl': !exists(json, 'moreInfoUrl') ? undefined : json['moreInfoUrl'],
        'currentActivitySession': !exists(json, 'currentActivitySession') ? undefined : ActivitySessionDtoFromJSON(json['currentActivitySession']),
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'isVisible': !exists(json, 'isVisible') ? undefined : json['isVisible'],
        'chooseDateFirst': !exists(json, 'chooseDateFirst') ? undefined : json['chooseDateFirst'],
        'availableTicketsCount': !exists(json, 'availableTicketsCount') ? undefined : json['availableTicketsCount'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
    };
}

export function VenueDtoToJSON(value?: VenueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'name': value.name,
        'description': value.description,
        'address': value.address,
        'postalCode': value.postalCode,
        'city': value.city,
        'phone': value.phone,
        'mapUrl': value.mapUrl,
        'imageUrl': value.imageUrl,
        'moreInfoUrl': value.moreInfoUrl,
        'currentActivitySession': ActivitySessionDtoToJSON(value.currentActivitySession),
        'isPublished': value.isPublished,
        'isVisible': value.isVisible,
        'chooseDateFirst': value.chooseDateFirst,
        'availableTicketsCount': value.availableTicketsCount,
        'unitName': value.unitName,
    };
}


