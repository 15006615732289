import * as React from "react";
import { Redirect, Route } from "react-router-dom";

// Components
import PrivateView from "./Private";

import * as Constants from "../utils/constants";
import * as Utils from "../utils";

export class ProtectedRoute extends Route<any> {
  public render() {
    if (Utils.validateToken()) {
      return (
        <Route>
          <PrivateView />
        </Route>
      );
    } else {
      return <Redirect to={{ pathname: Constants.loginPath }} />;
    }
  }
}
