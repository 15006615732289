import * as React from "react";

import CounterComponent from "./VenueDetailCounter";
import HistoryComponent from "./VenueDetailHistory";

const VenueDetailFreeAccess: React.FC<any> = (props: any) => {
    return (
        <div>
            <CounterComponent 
              {...props}
            />
            {props.venue?.currentActivitySession?.isOpen && ( 
              <HistoryComponent
               {...props}
              />
            )}
        </div>
    );
};

export default VenueDetailFreeAccess;