import React, { useState, useEffect } from "react";

// API
import * as VenueApi from "../../api/apis/VenueApi";
import { VenueDto } from "../../api/models/VenueDto";

// Components
import VenueListComponent from "../../components/Venues/VenueList";

import * as Utils from "../../utils";
import * as Constants from "../../utils/constants";

const VenueListLayout: React.FC<any> = (props: any) => {
  const [venues, setVenues] = useState<VenueDto[]>([]);
  const [time, setTime] = useState<number>(Date.now());

  document.title = Constants.appTitle + Constants.venueListTitle;

  // Refresh page
  useEffect(() => {
    let interval = setInterval(
      () => setTime(Date.now()),
      Constants.refreshInterval
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Get venues
  useEffect(() => {
    let venueApi = new VenueApi.VenueApi(Utils.getDefaultConfiguration());

    venueApi
      .getVenues({})
      .then((response: any) => {
        setVenues((venues: VenueDto[]) => venues = response.data);
      })
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  }, [time]);

  return (
    <div>
      <VenueListComponent venues={venues} />
    </div>
  );
};

export default VenueListLayout;
