import React, { useEffect, useState } from "react";
import * as Utils from "../../../../utils";
import OccupancyBar from "../../VenueOccupancyBar";
import { Modal } from "reactstrap";
import BookingModalComponent from "../BookingModalComponent";

import { VenueDto } from "../../../../api/models/VenueDto";

const VenueDetailCounter: React.FC<any> = (props: any) => {
  const [venue, setVenue] = useState(props.venue);
  const [modal, setModal] = useState(false);
  const [submittingForm, setSubmittingForm] = React.useState(false);

  useEffect(() => {
    let eventSource = Utils.getActivitySessionEventsourceConnection(
      props.currentSessionOid === ""
        ? venue.currentActivitySession?.oid
        : props.currentSessionOid
    );
    if (eventSource != undefined) {
      eventSource.onmessage = (e) => {
        venue.currentActivitySession === undefined &&
          props.setRefresh(new Date().getTime());
        updateVenue(JSON.parse(e.data));
      };
    }
  }, [props.currentSessionOid]);

  useEffect(() => {
    setVenue((venue: VenueDto) => (venue = props.venue));
  }, [props.venue]);

  const updateVenue = (data: any) => {
    data &&
      setVenue(
        (venue: VenueDto) =>
          (venue = {
            ...venue,
            currentActivitySession: {
              activityName: venue.currentActivitySession?.activityName,
              ticketsRequired: venue.currentActivitySession?.ticketsRequired,
              bookedTicketCount:
                venue.currentActivitySession?.bookedTicketCount,
              canBuyTicketsWithoutDiscount:
                venue.currentActivitySession?.canBuyTicketsWithoutDiscount,
              capacity: data.Capacity,
              highOccupancyLevel:
                venue.currentActivitySession?.highOccupancyLevel,
              isOpen: data.IsOpen,
              isPublished: venue.isPublished,
              mediumOccupancyLevel:
                venue.currentActivitySession?.mediumOccupancyLevel,
              occupancy: data.Occupancy,
              oid: data.Oid,
              startOn: data.StartOn,
              maxTicketsPerTransaction:
                venue.currentActivitySession?.maxTicketsPerTransaction,
              availableTicketCount:
                venue.currentActivitySession?.availableTicketCount,
            },
          })
      );
    props.setNewAccessLog(data);
  };
  return (
    <div>
      <div className="justify-content-center text-center">
        <div className="mt-4">
          <button
            className={
              !props.nightShift ||
              props.venue.currentActivitySession?.occupancy >=
                props.venue.currentActivitySession?.capacity
                ? "btn btn-sm btn-outline-light px-3 mb-2 mr-4 rounded-button"
                : "btn btn-sm btn-outline-white px-3 mb-2 mr-4 rounded-button"
            }
            onClick={() => props.setNightShift(!props.nightShift)}
          >
            {!props.nightShift ? (
              <div>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 1 16 16"
                  className="bi bi-moon"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.53 10.53a7 7 0 0 1-9.058-9.058A7.003 7.003 0 0 0 8 15a7.002 7.002 0 0 0 6.53-4.47z"
                  />
                </svg>{" "}
                Mode fosc
              </div>
            ) : (
              <div>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 1 16 16"
                  className="bi bi-sun"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3.5 8a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M8.202.28a.25.25 0 0 0-.404 0l-.91 1.255a.25.25 0 0 1-.334.067L5.232.79a.25.25 0 0 0-.374.155l-.36 1.508a.25.25 0 0 1-.282.19l-1.532-.245a.25.25 0 0 0-.286.286l.244 1.532a.25.25 0 0 1-.189.282l-1.509.36a.25.25 0 0 0-.154.374l.812 1.322a.25.25 0 0 1-.067.333l-1.256.91a.25.25 0 0 0 0 .405l1.256.91a.25.25 0 0 1 .067.334L.79 10.768a.25.25 0 0 0 .154.374l1.51.36a.25.25 0 0 1 .188.282l-.244 1.532a.25.25 0 0 0 .286.286l1.532-.244a.25.25 0 0 1 .282.189l.36 1.508a.25.25 0 0 0 .374.155l1.322-.812a.25.25 0 0 1 .333.067l.91 1.256a.25.25 0 0 0 .405 0l.91-1.256a.25.25 0 0 1 .334-.067l1.322.812a.25.25 0 0 0 .374-.155l.36-1.508a.25.25 0 0 1 .282-.19l1.532.245a.25.25 0 0 0 .286-.286l-.244-1.532a.25.25 0 0 1 .189-.282l1.508-.36a.25.25 0 0 0 .155-.374l-.812-1.322a.25.25 0 0 1 .067-.333l1.256-.91a.25.25 0 0 0 0-.405l-1.256-.91a.25.25 0 0 1-.067-.334l.812-1.322a.25.25 0 0 0-.155-.374l-1.508-.36a.25.25 0 0 1-.19-.282l.245-1.532a.25.25 0 0 0-.286-.286l-1.532.244a.25.25 0 0 1-.282-.189l-.36-1.508a.25.25 0 0 0-.374-.155l-1.322.812a.25.25 0 0 1-.333-.067L8.203.28zM8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11z"
                  />
                </svg>{" "}
                Mode clar
              </div>
            )}
          </button>
        </div>

        <div className="col col-auto">
          <h1 className="big-title mt-4">{venue?.name}</h1>
          {venue.currentActivitySession?.activityName && (
            <span className="row justify-content-center">
              {venue.currentActivitySession?.activityName}
            </span>
          )}
        </div>
      </div>

      {/* Closed venue */}

      {!venue?.currentActivitySession?.isOpen && (
        <div className="text-center">
          <br />
          <a
            href="#"
            onClick={() => props.openVenue(venue.oid)}
            className="badge p-2 closed-acces-badge mb-0 green bg-recintesgreen"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 1 16 16"
              className="bi bi-door-open-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2v13h1V2.5a.5.5 0 0 0-.5-.5H11zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"
              />
            </svg>{" "}
            Obrir accés
          </a>
          <h5 className="medium-title m-4">ACCÉS TANCAT</h5>
        </div>
      )}

      {/* Opened venue */}

      {venue?.currentActivitySession?.isOpen && (
        <div>
          <div className="text-center">
            <span className="medium-title mb-0" style={{ fontSize: "32pt" }}>
              <span className="counter-reserves" style={{ fontSize: "72pt" }}>
                {venue.currentActivitySession?.occupancy}
              </span>
              /{venue.currentActivitySession?.capacity}
            </span>
            {venue.currentActivitySession &&
              venue.currentActivitySession.ticketsRequired && (
                <span className="d-block margin-counter ">
                  {venue.currentActivitySession?.bookedTicketCount
                    ? venue.currentActivitySession.bookedTicketCount
                    : "0"}{" "}
                  places reservades /{" "}
                  <span className="text-success">
                    {venue.currentActivitySession?.capacity -
                      (venue.currentActivitySession?.bookedTicketCount !== 0
                        ? 0
                        : venue.currentActivitySession?.bookedTicketCount)}{" "}
                    disponibles
                  </span>
                </span>
              )}
          </div>

          <div className="row justify-content-center">
            <div className="col col-lg-7 col-md-7 col-sm-7 col-9">
              <div className="progress">
                <OccupancyBar venue={venue} />
              </div>
              {venue.currentActivitySession?.occupancy >=
              venue.currentActivitySession?.capacity ? (
                <h5 className="pt-3 text-center">
                  <strong style={{ color: "#e43100" }}>
                    <svg
                      width="1.0625em"
                      height="1em"
                      viewBox="0 1 17 16"
                      className="bi bi-exclamation-triangle-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                      />
                    </svg>{" "}
                    RECINTE PLE
                  </strong>
                </h5>
              ) : (
                <h5 className="pt-3 text-center">
                  <strong>
                    {venue.currentActivitySession?.capacity -
                      venue.currentActivitySession?.occupancy}
                  </strong>{" "}
                  places disponibles
                </h5>
              )}
            </div>
          </div>

          <div className="row justify-content-center my-4">
            <div className="col col-auto">
              <button
                type="button"
                className="btn btn-warning shadow btn-circle btn-xxl"
                onClick={() =>
                  venue.currentActivitySession?.occupancy > 0 &&
                  props.updateOccupancy(venue.currentActivitySession.oid, 2)
                }
              >
                <svg
                  className="bi bi-dash"
                  width="3em"
                  height="3em"
                  viewBox="2.5 1 21 21"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </button>
            </div>
            <div className="col col-auto">
              <button
                type="button"
                data-toggle="modal"
                data-target="#reservaModal"
                className={
                  props.nightShift
                    ? "btn btn-success shadow btn-circle btn-xxl"
                    : "btn btn-danger shadow btn-circle btn-xxl"
                }
                onClick={() => {
                  if (venue.currentActivitySession?.isOpen) {
                    venue.currentActivitySession.ticketsRequired
                      ? setModal(true)
                      : props.updateOccupancy(
                          venue.currentActivitySession.oid,
                          1
                        );
                  }
                  setSubmittingForm(false);
                }}
              >
                <svg
                  className="bi bi-plus"
                  width="3em"
                  height="3em"
                  viewBox="2.5 1 21 21"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <BookingModalComponent
          {...props}
          modal={modal}
          setModal={setModal}
          session={venue.currentActivitySession}
          submittingForm={submittingForm}
          setSubmittingForm={setSubmittingForm}
        />
      </Modal>
    </div>
  );
};
export default VenueDetailCounter;
