import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

// API
import * as VenueApi from "../../api/apis/VenueApi";
import * as ActivitySessionApi from "../../api/apis/ActivitySessionApi";
import * as AccessLogApi from "../../api/apis/AccessLogApi";
import * as TransactionApi from "../../api/apis/TransactionApi";
import { VenueDto } from "../../api/models/VenueDto";
import { AccessLogListDto } from "../../api/models/AccessLogListDto";
import { TransactionListDto } from "../../api/models/TransactionListDto";
import { TransactionDto } from "../../api/models/TransactionDto";

// Components
import VenueDetailComponent from "../../components/Venues/VenueDetail/VenueDetail";
import VenueHistoryComponent from "../../components/Venues/VenueDetail/VenueHistory";

import * as Utils from "../../utils";
import * as Constants from "../../utils/constants";

interface RouteParams {
  venueid: string;
}

const VenueDetailLayout = (props: any) => {
  const [venue, setVenue] = useState<VenueDto>(Constants.venue);
  const [logs, setLogs] = useState<AccessLogListDto[]>([]);
  const [fullLogs, setFullLogs] = useState<AccessLogListDto[]>([]);
  const [transactions, setTransactions] = useState<TransactionListDto[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionDto>();
  const [newAccessLog, setNewAccessLog] = useState<any>(null);
  const [currentSessionOid, setCurrentSessionOid] = useState<string>("");
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [occupancy, setOccupancy] = useState<number | undefined>(
    venue ? venue.currentActivitySession?.occupancy : 0
  );

  document.title = Constants.appTitle + Constants.venueDetailTitle;
  let params = useParams<RouteParams>();

  //Get venue by id
  useEffect(() => {
    new VenueApi.VenueApi(Utils.getDefaultConfiguration())
      .venuesVenueIdGet({ venueId: params.venueid })
      .then((response: any) => {
        setVenue(response.item);
      })
      .catch((response: any) => {
        Utils.showError(props.setAlert, response);
      });
  }, [params.venueid, refresh]);

  //Get access logs (3 history items) and transactions by session id
  useEffect(() => {
    setLoading(true);
    setTransactions([]);
    if (venue && venue.oid !== "" && venue.currentActivitySession) {
      new AccessLogApi.AccessLogApi(Utils.getDefaultConfiguration())
        .getAccessLogs({
          sessionId: venue.currentActivitySession.oid as string,
          pageSize: 3,
        })
        .then((response: any) => {
          setLogs(response.data);
        })
        .catch((response: any) => {
          response.json().then((data: any) => {
            alert(Utils.getError(data.errors));
          });
        });
      new TransactionApi.TransactionApi(Utils.getDefaultConfiguration())
        .getTransactions({
          sessionId: venue.currentActivitySession.oid as string,
          search: searchString,
        })
        .then((response: any) => {
          setTransactions(response.data);
        })
        .then(() => setLoading(false))
        .catch((response: any) => {
          Utils.showError(props.setAlert, response);
        });
    }
  }, [venue, refresh, searchString]);

  // Set access logs
  useEffect(() => {
    if (newAccessLog?.AccessLog) {
      const newLog: AccessLogListDto = {
        date: newAccessLog.AccessLog.Date,
        operation: newAccessLog.AccessLog.Operation,
        userFullName: newAccessLog.AccessLog.UserFullName,
        currentCount: newAccessLog.AccessLog.CurrentCount,
      };
      setLogs([newLog, ...logs.slice(0, 18)]);
    }
  }, [newAccessLog]);

  // Open venue post
  const openVenue = (venueId: any) => {
    setCurrentSessionOid("");
    new ActivitySessionApi.ActivitySessionApi(Utils.getDefaultConfiguration())
      .openVenue({
        venueId: venueId,
      })
      .then((data) => {
        if (data.item?.oid) {
          setCurrentSessionOid(data.item.oid.toString());
        }
      })
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  };

  // Update occupancy post
  const updateOccupancy = (sessionOid: string, operation: any) => {
    let data: ActivitySessionApi.PostAccessOperationRequest = {
      sessionId: sessionOid,
      accessOperationPostDto: { accessOperation: operation },
    };
    new ActivitySessionApi.ActivitySessionApi(Utils.getDefaultConfiguration())
      .postAccessOperation(data)
      .then((response) => {
        setOccupancy(response.item?.occupancy);
      })
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  };

  // Post transaction
  const postTransation = (data: any, sessionId: string) => {
    new TransactionApi.TransactionApi(Utils.getDefaultConfiguration())
      .postTransactionAndCheckin({
        sessionId: sessionId,
        transactionPostDto: data,
      })
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  };

  // Update transaction
  const updateTransaction = (
    sessionId: string,
    transactionId: string,
    ticketCount: number
  ) => {
    setLoading(true);
    new TransactionApi.TransactionApi(Utils.getDefaultConfiguration())
      .putTransaction({
        sessionId: sessionId,
        transactionId: transactionId,
        transactionPutDto: { ticketCount: ticketCount },
      })
      .then(
        () =>
          venue.currentActivitySession?.ticketsRequired &&
          setRefresh(new Date().getTime())
      )
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  };

  // Get transaction by session and transaction id
  const getSelectedTransaction = (sessionId: string, transactionId: string) => {
    new TransactionApi.TransactionApi(Utils.getDefaultConfiguration())
      .getTransaction({ sessionId: sessionId, transactionId: transactionId })
      .then((response: any) => {
        setSelectedTransaction(response.item);
      })
      .catch((response) => {
        Utils.showError(props.setAlert, response);
      });
  };

  // Get access logs
  const getFullAccessLogs = (currentActivitySessionId: string) => {
    new AccessLogApi.AccessLogApi(Utils.getDefaultConfiguration())
      .getAccessLogs({
        // sessionId: venue.currentActivitySession?.oid as string
        sessionId: currentActivitySessionId as string,
      })
      .then((response: any) => {
        setFullLogs(response.data);
      })
      .catch((response: any) => {
        response.json().then((data: any) => {
          alert(Utils.getError(data.errors));
        });
      });
  };

  if ((venue && venue.oid !== "") || (props.venue && props.venue !== null)) {
    return (
      <div>
        <Switch>
          <Route exact path={Constants.detailPath + "/history"}>
            <VenueHistoryComponent
              venue={venue}
              fullLogs={fullLogs}
              getFullAccessLogs={getFullAccessLogs}
            />
          </Route>
          <Route exact path={Constants.detailPath}>
            <VenueDetailComponent
              venue={venue}
              occupancy={occupancy}
              currentSessionOid={currentSessionOid}
              logs={logs}
              setNewAccessLog={setNewAccessLog}
              transactions={transactions}
              selectedTransaction={selectedTransaction}
              openVenue={openVenue}
              updateOccupancy={updateOccupancy}
              postTransaction={postTransation}
              updateTransaction={updateTransaction}
              getSelectedTransaction={getSelectedTransaction}
              setRefresh={setRefresh}
              loading={loading}
              setSearchString={setSearchString}
              searchString={searchString}
            />
          </Route>
        </Switch>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default VenueDetailLayout;
