/* tslint:disable */
/* eslint-disable */
/**
 * Fila12 API
 * Through this API you can access .
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Channel data transfer object.
 * @export
 * @interface TransactionListDto
 */
export interface TransactionListDto {
    /**
     * Gets or sets the identifier of the transaction.
     * @type {string}
     * @memberof TransactionListDto
     */
    oid?: string;
    /**
     * Gets or sets the name of the person whom the transaction belongs to.
     * @type {string}
     * @memberof TransactionListDto
     */
    name?: string | null;
    /**
     * Gets or sets the quantity of tickets booked in the transaction.
     * @type {number}
     * @memberof TransactionListDto
     */
    ticketCount?: number;
}

export function TransactionListDtoFromJSON(json: any): TransactionListDto {
    return TransactionListDtoFromJSONTyped(json, false);
}

export function TransactionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oid': !exists(json, 'oid') ? undefined : json['oid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ticketCount': !exists(json, 'ticketCount') ? undefined : json['ticketCount'],
    };
}

export function TransactionListDtoToJSON(value?: TransactionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oid': value.oid,
        'name': value.name,
        'ticketCount': value.ticketCount,
    };
}


