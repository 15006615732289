import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import NavBar from "../components/Common/NavBar";
import VenueDetailLayout from "../layouts/Venues/VenueDetail";
import VenueListLayout from "../layouts/Venues/VenueList";
import VenueStatusLayout from "../layouts/Venues/VenueStatus";
import AlertComponent from "../components/Common/Alert";

import * as Constants from "../utils/constants";
import {Alert} from "../models/alert";

const Private: React.FC<any> = (props: any) => {
  const [alert, setAlert] = useState<Alert>();

  return (
    <div>
      <NavBar />

      <AlertComponent setAlert={setAlert} alert={alert} />

      <Switch>
        <Route path={Constants.detailPath}>
          <VenueDetailLayout setAlert={setAlert} />
        </Route>

        <Route exact path={Constants.venueStatusPath}>
          <VenueStatusLayout setAlert={setAlert} />
        </Route>
        
        <Route exact path={Constants.listPath}>
          <VenueListLayout setAlert={setAlert} />
        </Route>
      </Switch>
    </div>
  );
};

export default Private;
