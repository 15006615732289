import React from "react";
import * as Constants from "../../utils/constants";
import {Alert} from "../../models/alert";

const AlertComponent: React.FC<any> = (props: any) => {
  return (
    <div>
      {props.alert && props.alert.text && (
        <div
          className="alert alert-danger alert-dismissible fade show mt-2"
          role="alert"
        >
          {props.alert.text}
          <br />
          {props.alert.loginLink ? (
            <a href={Constants.loginPath}>
              Anar a la pàgina d'inici de sessió.
            </a>
          ) : null}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() =>
              props.setAlert((alert: Alert) => alert = {
                text: "",
                color: "",
                visible: false,
                loginlink: false,
              })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AlertComponent;
